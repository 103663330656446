import api from '@/helpers/_api'
import { CreateChatMessageRequest } from '@/models/api/request/CreateChatMessageRequest'
import { ChatMessageResource } from '@/models/api/resource/ChatMessageResource'
import { ChatNotificationResource } from '@/models/api/resource/ChatNotificationResource'
import { ChatSessionResource } from '@/models/api/resource/ChatSessionResource'
import { DateTime } from 'luxon'

export default class ChatService {

    static async getSession(projectId: number): Promise<ChatSessionResource> {
        const url = `projects/${projectId}/briefs/chat`
        const resource = (await api.get(url)).data.data
        resource.createdAt = DateTime.fromISO(resource.createdAt)
        resource.archivedAt ? DateTime.fromISO(resource.archivedAt) : null
        return resource
    }

    static async getMessages(projectId: number): Promise<ChatMessageResource[]> {
        const url = `projects/${projectId}/briefs/chat/messages`
        return (await api.get(url)).data.data.map(r => {
            r.createdAt = DateTime.fromISO(r.createdAt)
            return r
        })
    }

    static async getUnreadNotifications(projectId: number): Promise<ChatNotificationResource[]> {
        const url = `projects/${projectId}/briefs/chat/notifications/unread`
        return (await api.get(url)).data.data.map(r => {
            r.createdAt = DateTime.fromISO(r.createdAt)
            r.readAt = r.readAt ? DateTime.fromISO(r.readAt) : null
            return r
        })
    }

    static async flagUnreadNotificationsAsRead(projectId: number): Promise<void> {
        const url = `projects/${projectId}/briefs/chat/notifications/unread`
        await api.put(url)
    }

    static async createMessage(projectId: number, request: CreateChatMessageRequest): Promise<ChatMessageResource> {
        const url = `projects/${projectId}/briefs/chat/messages`
        const resource = (await api.post(url, request)).data.data
        resource.createdAt = DateTime.fromISO(resource.createdAt)
        resource.archivedAt = resource.archivedAt ? DateTime.fromISO(resource.archivedAt) : null
        return resource
    }
}
