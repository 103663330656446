import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any) {
  const _component_ClosureBanner = _resolveComponent("ClosureBanner")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppError = _resolveComponent("AppError")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_PageSettings = _resolveComponent("PageSettings")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ClosureBanner),
    (!_ctx.headerModeOff)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isGlobalError)
      ? (_openBlock(), _createBlock(_component_AppError, { key: 1 }))
      : (_openBlock(), _createBlock(_component_RouterView, {
          key: _ctx.$route.path
        })),
    (!_ctx.footerModeOff)
      ? (_openBlock(), _createBlock(_component_AppFooter, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.$store.getters['isAuthenticated'] && _ctx.$store.getters['isAdministrator'] && _ctx.$router.currentRoute.value.meta?.serverMode)
      ? (_openBlock(), _createBlock(_component_PageSettings, { key: 4 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Loading, {
      active: _ctx.isLoading,
      "can-cancel": false,
      "is-full-page": true,
      loader: "spinner"
    }, null, 8, ["active"])
  ], 64))
}