export enum Style {
    ART_DECO       = 'ART_DECO',
    BOHO           = 'BOHO',
    COASTAL        = 'COASTAL',
    CONTEMPORARY   = 'CONTEMPORARY',
    COUNTRY_RUSTIC = 'COUNTRY_RUSTIC',
    ECLECTIC       = 'ECLECTIC',
    HAMPTONS       = 'HAMPTONS',
    INDUSTRIAL     = 'INDUSTRIAL',
    MID_CENTURY    = 'MID_CENTURY',
    MINIMALIST     = 'MINIMALIST',
    MODERN         = 'MODERN',
    SCANDI         = 'SCANDI',
    TRADITIONAL    = 'TRADITIONAL',
    TRANSITIONAL   = 'TRANSITIONAL',
}