import { elapsedSeconds } from '@/helpers/date'
import Filter, { FilterOperator } from '@/models/api/filters/Filter'
import { CloseProjectRequest } from '@/models/api/request/CloseProjectRequest'
import { CreateShoppingItemRequest } from '@/models/api/request/CreateShoppingItemRequest'
import { PackageRequest } from '@/models/api/request/PackageRequest'
import { PublishPackageRequest } from '@/models/api/request/PublishPackageRequest'
import { UpdateShoppingItemRequest } from '@/models/api/request/UpdateShoppingItemRequest'
import { BriefResource } from '@/models/api/resource/BriefResource'
import { PackageResource } from '@/models/api/resource/ProjectPackageResource'
import { ProjectResource } from '@/models/api/resource/ProjectResource'
import { ShoppingItemResource } from '@/models/api/resource/ShoppingItemResource'
import { ProjectPackage } from '@/models/ProjectPackage'
import BriefService from '@/services/BriefService'
import ProjectService from '@/services/ProjectService'
import { BaseActions } from '@/store/BaseActions'
import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import { useRoute } from 'vue-router'

export declare const project: ProjectResource
export declare const projects: ProjectResource[]
export declare const isProjectClosed: boolean

export declare function loadProjects(): Promise<void>
export declare function loadProject(projectId: number): Promise<ProjectResource>
export declare function reloadProject(projectId: number): Promise<ProjectResource>
export declare function getProject(projectId: number): ProjectResource
export declare function closeProject(payload: { projectId: number, request: CloseProjectRequest }): Promise<ProjectResource>
export declare function flagProjectAsShortlistRequested(projectId: number): Promise<ProjectResource>
export declare function reloadPackage(projectId: number): Promise<PackageResource>
export declare function updatePackage(payload: { projectId: number, changes: Partial<ProjectPackage> }): Promise<PackageResource>
export declare function publishPackage(payload: { projectId: number, data: PublishPackageRequest }): Promise<PackageResource>
export declare function flagPackageAsViewed(projectId: number): void
export declare function createShoppingItem(payload: { projectId: number, shoppingItemRequest: CreateShoppingItemRequest }): Promise<ShoppingItemResource>
export declare function updateShoppingItem(payload: { projectId: number, shoppingItemId: number, shoppingItemRequest: UpdateShoppingItemRequest }): Promise<ShoppingItemResource>
export declare function deleteShoppingItem(payload: { projectId: number, shoppingItemId: number }): Promise<void>
export declare function flagShoppingItemAsPurchased(payload: { projectId: number, shoppingItemId: number }): Promise<void>
export declare function flagShoppingItemAsNotPurchased(payload: { projectId: number, shoppingItemId: number }): Promise<void>
export declare function requestTradeQuoteForShoppingItem(payload: { projectId: number, shoppingItemId: number }): Promise<void>

function getProjectId(): number | null {
    return Number(useRoute().params['projectId'])
}

type State = {
    $_lastBulkLoaded: Date
    $_lastModified: Date
    activeProjectId: number
    projects: Map<number, ProjectResource>
}

type Actions = {
    setActiveProjectId(payload: { projectId: number }): void
    loadProjects: typeof loadProjects
    loadProject: typeof loadProject
    loadBrief(payload: { projectId?: number, reload?: boolean }): Promise<void>
    reloadProject: typeof reloadProject
    getProject: typeof getProject
    closeProject: typeof closeProject
    flagProjectAsShortlistRequested: typeof flagProjectAsShortlistRequested
    reloadPackage: typeof reloadPackage
    updatePackage: typeof updatePackage
    publishPackage: typeof publishPackage
    flagPackageAsViewed: typeof flagPackageAsViewed
    createShoppingItem: typeof createShoppingItem
    updateShoppingItem: typeof updateShoppingItem
    deleteShoppingItem: typeof deleteShoppingItem
    flagShoppingItemAsPurchased: typeof flagShoppingItemAsPurchased
    flagShoppingItemAsNotPurchased: typeof flagShoppingItemAsNotPurchased
    requestTradeQuoteForShoppingItem: typeof requestTradeQuoteForShoppingItem
    invalidateProjects: () => void
}

type Getters = {
    activeProjectId(state: State): number
    projects(state: State): typeof projects
    project(state: State): typeof project
    isProjectClosed(state: State): typeof isProjectClosed
    projectsLoaded(state: State): boolean
    projectsEmpty(state: State): boolean
}

type Mutations = {
    SET_ACTIVE_PROJECT_ID(state: State, payload: { projectId: number }): void
    SET_LAST_BULK_LOADED(state: State): void
    SET_LAST_MODIFIED(state: State, payload: { projectResources: ProjectResource[] }): void
    CLEAR_LAST_BULK_LOADED(state: State): void
    ADD_PROJECT(state: State, payload: { projectResource: ProjectResource }): void
    UPDATE_PROJECT(state: State, payload: { projectResource: ProjectResource }): void
    UPDATE_BRIEF(state: State, payload: { projectId: number, resource: BriefResource }): void
    UPDATE_PACKAGE(state: State, payload: { projectId: number, packageResource: PackageResource })
    ADD_SHOPPING_ITEM(state: State, payload: { projectId: number, shoppingItemResource: ShoppingItemResource }): void
    UPDATE_SHOPPING_ITEM(state: State, payload: { projectId: number, shoppingItemId: number, shoppingItemResource: ShoppingItemResource }): void
    DELETE_SHOPPING_ITEM(state: State, payload: { projectId: number, shoppingItemId: number }): void
}

const PROJECTS_PAGE_SIZE = 12
const PROJECTS_TTL_SECONDS = 300

const state: State = {
    $_lastBulkLoaded: null,
    $_lastModified: null,
    activeProjectId: 0,
    projects: new Map(),
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    setActiveProjectId({ commit }, { projectId }) {
        commit('SET_ACTIVE_PROJECT_ID', { projectId })
    },
    async loadProjects({ state, commit }) {
        if (state.$_lastBulkLoaded && elapsedSeconds(state.$_lastBulkLoaded) < PROJECTS_TTL_SECONDS) {
            return
        }
        let nextPage = 1
        do {
            const filter = (new Filter())
                .add('page', FilterOperator.SIZE, PROJECTS_PAGE_SIZE)
                .add('page', FilterOperator.NUMBER, nextPage)
            const collection = await ProjectService.getProjects(filter)
            const projectResources = collection.toArray()
            nextPage = collection.links.next !== null ? ++nextPage : null
            projectResources.forEach(projectResource => commit('ADD_PROJECT', { projectResource }))
        }
        while (nextPage)
        commit('SET_LAST_BULK_LOADED')
    },
    async loadProject({ commit }, projectId) {
        const projectResource = await ProjectService.getProject(projectId)
        commit('ADD_PROJECT', { projectResource })
        return projectResource
    },
    async loadBrief({ commit, state, rootGetters }, payload = {}) {
        const projectId = payload.projectId ?? rootGetters['activeProjectId']
        if (state.projects.get(projectId).brief && !payload?.reload) {
            return
        }
        const resource = await BriefService.getBrief(projectId)
        commit('UPDATE_BRIEF', { projectId, resource })
    },
    async reloadProject({ commit }, projectId) {
        const projectResource = await ProjectService.getProject(projectId)
        commit('UPDATE_PROJECT', { projectResource })
        return projectResource
    },
    getProject({ getters }, projectId) {
        return cloneDeep(getters.projects.find(p => p.id == projectId))
    },
    async closeProject({ commit }, { projectId, request }) {
        const projectResource = await ProjectService.closeProject(projectId, request)
        commit('UPDATE_PROJECT', { projectResource })
        return projectResource
    },
    async flagProjectAsShortlistRequested({ commit }, projectId) {
        const projectResource = await ProjectService.flagProjectAsShortlistRequested(projectId)
        commit('UPDATE_PROJECT', { projectResource })
        return projectResource
    },
    async reloadPackage({ commit }, projectId) {
        const packageResource = await ProjectService.getPackage(projectId)
        commit('UPDATE_PACKAGE', { projectId, packageResource })
        return packageResource
    },
    async updatePackage({ commit }, { projectId, changes }) {
        const packageResource = await ProjectService.updatePackage(projectId, <PackageRequest>changes)
        commit('UPDATE_PACKAGE', { projectId, packageResource })
        return packageResource
    },
    async publishPackage({ commit }, { projectId, data }) {
        const packageResource = await ProjectService.publishPackage(projectId, data)
        commit('UPDATE_PACKAGE', { projectId, packageResource })
        return packageResource
    },
    async flagPackageAsViewed({ commit }, projectId) {
        const packageResource = await ProjectService.flagPackageAsViewed(projectId)
        commit('UPDATE_PACKAGE', { projectId, packageResource })
    },
    async createShoppingItem({ commit }, { projectId, shoppingItemRequest }) {
        const shoppingItemResource = await ProjectService.createShoppingItem(projectId, shoppingItemRequest)
        commit('ADD_SHOPPING_ITEM', { projectId, shoppingItemResource })
        return shoppingItemResource
    },
    async updateShoppingItem({ commit }, { projectId, shoppingItemId, shoppingItemRequest }) {
        const shoppingItemResource = await ProjectService.updateShoppingItem(projectId, shoppingItemId, shoppingItemRequest)
        commit('UPDATE_SHOPPING_ITEM', { projectId, shoppingItemId, shoppingItemResource })
        return shoppingItemResource
    },
    async deleteShoppingItem({ commit }, { projectId, shoppingItemId }) {
        await ProjectService.deleteShoppingItem(projectId, shoppingItemId)
        commit('DELETE_SHOPPING_ITEM', { projectId, shoppingItemId })
    },
    async flagShoppingItemAsPurchased({ commit }, { projectId, shoppingItemId }) {
        const shoppingItemResource = await ProjectService.patchShoppingItem(projectId, shoppingItemId, { isPurchased: 1 })
        commit('UPDATE_SHOPPING_ITEM', { projectId, shoppingItemId, shoppingItemResource })
    },
    async flagShoppingItemAsNotPurchased({ commit }, { projectId, shoppingItemId }) {
        const shoppingItemResource = await ProjectService.patchShoppingItem(projectId, shoppingItemId, { isPurchased: 0 })
        commit('UPDATE_SHOPPING_ITEM', { projectId, shoppingItemId, shoppingItemResource })
    },
    async requestTradeQuoteForShoppingItem({ commit }, { projectId, shoppingItemId }) {
        const shoppingItemResource = await ProjectService.patchShoppingItem(projectId, shoppingItemId, { isTradeRequested: 1 })
        commit('UPDATE_SHOPPING_ITEM', { projectId, shoppingItemId, shoppingItemResource })
    },
    invalidateProjects({ commit }) {
        commit('CLEAR_LAST_BULK_LOADED')
    },
}

const getters: Getters = {
    activeProjectId(state) {
        return state.activeProjectId
    },
    projects(state) {
        const projects = Array.from(state.projects.values())
        return orderBy(projects, [ 'createdAt' ], [ 'desc' ])
    },
    project(state) {
        const projectId = state.activeProjectId ?? getProjectId()
        return state.projects.get(projectId)
    },
    isProjectClosed(state) {
        const projectId = getProjectId()
        return state.projects.get(projectId)?.closedAt !== null
    },
    projectsLoaded(state) {
        return !!state.$_lastBulkLoaded
    },
    projectsEmpty(state) {
        return state.projects.size === 0
    },
}

const mutations: Mutations = {
    SET_ACTIVE_PROJECT_ID(state, { projectId }) {
        state.activeProjectId = projectId
    },
    SET_LAST_BULK_LOADED(state) {
        state.$_lastBulkLoaded = new Date(Date.now())
    },
    SET_LAST_MODIFIED(state, { projectResources }) {
        for (const resource of projectResources) {
            if (resource.updatedAt > state.$_lastModified) {
                state.$_lastModified = resource.updatedAt
            }
        }
    },
    CLEAR_LAST_BULK_LOADED(state) {
        state.$_lastBulkLoaded = null
    },
    ADD_PROJECT(state, { projectResource }) {
        state.projects.set(projectResource.id, projectResource)
    },
    UPDATE_PROJECT(state, { projectResource }) {
        state.projects.set(projectResource.id, projectResource)
    },
    UPDATE_BRIEF(state, { projectId, resource }) {
        state.projects.get(projectId).brief = resource
    },
    UPDATE_PACKAGE(state, { projectId, packageResource }) {
        state.projects.get(projectId).package = packageResource
    },
    ADD_SHOPPING_ITEM(state, { projectId, shoppingItemResource }) {
        state.projects.get(projectId).package.shoppingItems.push(shoppingItemResource)
    },
    UPDATE_SHOPPING_ITEM(state, { projectId, shoppingItemId, shoppingItemResource }) {
        const project = state.projects.get(projectId)
        const shoppingItem = project.package.shoppingItems.find(si => si.id === shoppingItemId)
        Object.assign(shoppingItem, shoppingItemResource)
    },
    DELETE_SHOPPING_ITEM(state, { projectId, shoppingItemId }) {
        const project = state.projects.get(projectId)
        const shoppingItemIndex = project.package.shoppingItems.findIndex(si => si.id === shoppingItemId)
        project.package.shoppingItems.splice(shoppingItemIndex, 1)
    },
}

export default { state, actions, getters, mutations }
