import api from '@/helpers/_api'
import { appConfig } from '@/helpers/_config'
import { RoomTypeResource } from '@/models/api/resource/RoomTypeResource'

export default class RoomService {

    static async getRooms(): Promise<RoomTypeResource[]> {
        const username = appConfig.username
        const password = appConfig.password
        const response = (await api.get('/room-types', { auth: { username, password } }))
        return <RoomTypeResource[]>response.data.data
    }
}
