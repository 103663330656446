import { createAxios } from '@/helpers/_api'
import { appConfig } from '@/helpers/_config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare'
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons/faPaperPlane'
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons/faStar'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createGtm } from '@gtm-support/vue-gtm'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import VueIntercom from 'vue3-intercom'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faCalendarAlt,
    faCheckCircle,
    faCheckSquare,
    faClock,
    faLock,
    faFacebook,
    faGlobeAmericas,
    faLinkedin,
    faSearch,
    faTwitter,
    faPaperPlane,
    faPinterest,
    faInstagram,
    faPlus,
    faSpinner,
    faSquare,
    fasStar,
    farStar,
    faTimes,
    faTimesCircle,
)

const app = createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(VueAxios, createAxios())
    .use(VueSvgInlinePlugin)

if (appConfig.gtmCode) {
    app.use(createGtm({ id: appConfig.gtmCode, vueRouter: router }))
}

if (appConfig.gaCode) {
    app.use(VueGtag, { config: { id: appConfig.gaCode } }, router)
}

if (appConfig.intercomCode) {
    app.use(VueIntercom, { appId: appConfig.intercomCode })
}

app.mount('#app')
