import { BaseActions } from '@/store/BaseActions'

export declare const isGlobalError: typeof state.isGlobalError
export declare const isLoading: typeof state.isLoading

export declare function toggleGlobalError(isGlobalError: boolean): boolean
export declare function toggleLoading(isLoading: boolean): boolean

type State = typeof state

type Actions = {
    toggleGlobalError: typeof toggleGlobalError
    toggleLoading: typeof toggleLoading
}

type Getters = {
    isGlobalError(state: State): typeof state.isGlobalError
    isLoading(state: State): typeof state.isLoading
}

type Mutations = {
    TOGGLE_GLOBAL_ERROR(state: State, isGlobalError: boolean): void
    TOGGLE_LOADING(state: State, isLoading: boolean): void
}

const state: {
    isGlobalError: boolean
    isLoading: boolean,
} = {
    isGlobalError: false,
    isLoading    : false,
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    toggleGlobalError({ commit }, isGlobalError) {
        commit('TOGGLE_GLOBAL_ERROR', isGlobalError)
        return isGlobalError
    },
    toggleLoading({ commit }, isLoading: boolean) {
        commit('TOGGLE_LOADING', isLoading)
        return isLoading
    },
}

const getters: Getters = {
    isGlobalError(state) {
        return state.isGlobalError
    },
    isLoading(state) {
        return state.isLoading
    },
}

const mutations: Mutations = {
    TOGGLE_GLOBAL_ERROR(state, isGlobalError) {
        state.isGlobalError = isGlobalError
    },
    TOGGLE_LOADING(state: State, isLoading: boolean) {
        state.isLoading = isLoading
    },
}

export default { state, actions, getters, mutations }

