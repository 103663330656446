import { Style } from '@/enums/Style'
import { options } from '@/helpers/_api'
import { apiConfig, appConfig } from '@/helpers/_config'
import { CreateStyleQuizResultRequest } from '@/models/api/request/CreateStyleQuizResultRequest'
import { StyleQuizResource } from '@/models/api/resource/StyleQuizResource'
import axios from 'axios'

export default class StyleQuizService {

    static artDecoStyleImages       = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /art-deco-*/, 'sync'))
    static bohoStyleImages          = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /boho-*/, 'sync'))
    static coastalStyleImages       = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /coastal-*/, 'sync'))
    static contemporaryStyleImages  = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /contemporary-*/, 'sync'))
    static countryRusticStyleImages = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /country-rustic-*/, 'sync'))
    static eclecticStyleImages      = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /eclectic-*/, 'sync'))
    static hamptonsStyleImages      = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /hamptons-*/, 'sync'))
    static industrialStyleImages    = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /industrial-*/, 'sync'))
    static midCenturyStyleImages    = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /mid-century-*/, 'sync'))
    static minimalistStyleImages    = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /minimalist-*/, 'sync'))
    static modernStyleImages        = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /modern-*/, 'sync'))
    static scandiStyleImages        = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /scandi-*/, 'sync'))
    static traditionalStyleImages   = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /traditional-*/, 'sync'))
    static transitionalStyleImages  = StyleQuizService.mapImagesContext(require.context('@/assets/styles', false, /transitional-*/, 'sync'))

    static styleImages: Record<string, string[]> = {
        [Style.ART_DECO]: StyleQuizService.artDecoStyleImages,
        [Style.BOHO]: StyleQuizService.bohoStyleImages,
        [Style.COASTAL]: StyleQuizService.coastalStyleImages,
        [Style.CONTEMPORARY]: StyleQuizService.contemporaryStyleImages,
        [Style.COUNTRY_RUSTIC]: StyleQuizService.countryRusticStyleImages,
        [Style.ECLECTIC]: StyleQuizService.eclecticStyleImages,
        [Style.HAMPTONS]: StyleQuizService.hamptonsStyleImages,
        [Style.INDUSTRIAL]: StyleQuizService.industrialStyleImages,
        [Style.MID_CENTURY]: StyleQuizService.midCenturyStyleImages,
        [Style.MINIMALIST]: StyleQuizService.minimalistStyleImages,
        [Style.MODERN]: StyleQuizService.modernStyleImages,
        [Style.SCANDI]: StyleQuizService.scandiStyleImages,
        [Style.TRADITIONAL]: StyleQuizService.traditionalStyleImages,
        [Style.TRANSITIONAL]: StyleQuizService.transitionalStyleImages,
    }

    static async createStyleQuiz(request: CreateStyleQuizResultRequest): Promise<StyleQuizResource[]> {
        const client   = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        const username = appConfig.username
        const password = appConfig.password
        const response = await client.post('api/style-quiz', request, { auth: { username, password } })
        return <StyleQuizResource[]>response.data.data
    }

    private static mapImagesContext(context: __WebpackModuleApi.RequireContext): string[] {
        return context.keys().map(f => context(f))
    }
}
