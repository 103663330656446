import { ReferralOptionResource } from '@/models/api/resource/ReferralOptionResource'
import ReferralOptionService from '@/services/ReferralOptionService'
import { BaseContext } from '@/store/BaseContext'

type Context = BaseContext<State, Actions, Getters, Mutations>

type State = {
    referralOptions: Set<ReferralOptionResource>
}

type Actions = {
    loadReferralOptions(context: Context): Promise<void>
}

type Mutations = {
    APPEND_REFERRAL_OPTION(state: State, payload: { resource: ReferralOptionResource }): void
}

type Getters = {
    referralOptions(state: State): ReferralOptionResource[]
    referralOption(state: State): (id) => ReferralOptionResource
}

const state: State = {
    referralOptions: new Set(),
}

const actions: Actions = {
    async loadReferralOptions({ state, commit }): Promise<void> {
        if (state.referralOptions.size !== 0) {
            return
        }
        const resources = await ReferralOptionService.loadReferralOptions()
        resources.forEach(resource => commit('APPEND_REFERRAL_OPTION', { resource }))
    },
}

const mutations: Mutations = {
    APPEND_REFERRAL_OPTION(state, { resource }) {
        state.referralOptions.add(resource)
    },
}

const getters: Getters = {
    referralOptions(state) {
        return Array.from(state.referralOptions.values()).sort((a, b) => {
            return a.order - b.order
        })
    },
    referralOption(state) {
        return (id) => {
            for (const r of state.referralOptions.values()) {
                if (r.id === id) {
                    return r
                }
            }
        }
    }
}

export default { state, actions, mutations, getters }
