import { Budget } from '@/enums/Budget'
import { DwellingType } from '@/enums/DwellingType'
import { Occupant } from '@/enums/Occupant'
import { PropertyType } from '@/enums/PropertyType'
import { RedesignReason } from '@/enums/RedesignReason'
import { Room } from '@/enums/Room'
import { Timeline } from '@/enums/Timeline'
import { PatchAccountProfileRequest } from '@/models/api/request/PatchAccountProfileRequest'
import { AddressResource } from '@/models/api/resource/AddressResource'
import AccountService from '@/services/AccountService'
import { BaseActions } from '@/store/BaseActions'
import isObject from 'lodash/isObject'
import merge from 'lodash/merge'

export declare const $dirty: string[]
export declare const firstName: string
export declare const lastName: string
export declare const emailAddress: string
export declare const password: string
export declare const address: AddressResource
export declare const phoneNumber: string
export declare const budget: Budget
export declare const dwellingType: DwellingType
export declare const occupants: Occupant[]
export declare const propertyType: PropertyType
export declare const redesignReason: RedesignReason
export declare const rooms: Room[]
export declare const timeline: Timeline

export declare function setAttributes(attributes: object): void
export declare function appendOccupant(occupant: Occupant): void
export declare function removeOccupant(occupant: Occupant): void
export declare function appendRoomSelection(room: Room): void
export declare function removeRoomSelection(room: Room): void
export declare function loadAccountProfile(): Promise<void>
export declare function saveAccountProfile(): Promise<void>
export declare function registerAccount(): Promise<void>

type State = {
    $dirty: Set<string>,
    firstName: typeof firstName,
    lastName: typeof lastName
    emailAddress: typeof emailAddress
    password: typeof password
    address: typeof address
    phoneNumber: typeof phoneNumber
    budget: typeof budget
    dwellingType: typeof dwellingType
    occupants: Set<Occupant>
    propertyType: typeof propertyType
    redesignReason: RedesignReason
    rooms: Set<Room>
    timeline: Timeline
}

type Actions = {
    setAttributes: typeof setAttributes
    appendOccupant: typeof appendOccupant
    removeOccupant: typeof removeOccupant
    appendRoomSelection: typeof appendRoomSelection
    removeRoomSelection: typeof removeRoomSelection
    loadAccountProfile: typeof loadAccountProfile
    saveAccountProfile: typeof saveAccountProfile
    registerAccount: typeof registerAccount
}

type Mutations = {
    SET_ATTRIBUTES(state: State, payload: object): void
    CLEAR_DIRTY(state: State): void
    APPEND_OCCUPANT(state: State, payload: { occupant: Occupant }): void
    REMOVE_OCCUPANT(state: State, payload: { occupant: Occupant }): void
    APPEND_ROOM_SELECTION(state: State, payload: { room: Room }): void
    REMOVE_ROOM_SELECTION(state: State, payload: { room: Room }): void
}

type Getters = {
    $dirty(state: State): typeof $dirty
    firstName(state: State): typeof firstName
    lastName(state: State): typeof lastName
    emailAddress(state: State): typeof emailAddress
    password(state: State): typeof password
    address(state: State): typeof address
    phoneNumber(state: State): typeof phoneNumber
    budget(state: State): typeof budget
    dwellingType(state: State): typeof dwellingType
    occupants(state: State): typeof occupants
    propertyType(state: State): typeof propertyType
    redesignReason(state: State): typeof redesignReason
    rooms(state: State): typeof rooms
    timeline(state: State): typeof timeline
}

const state: State = {
    $dirty: new Set(),
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    address: {
        street: [],
        suburb: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
    },
    phoneNumber: '',
    budget: null,
    dwellingType: null,
    occupants: new Set(),
    propertyType: null,
    redesignReason: null,
    rooms: new Set(),
    timeline: null,
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    setAttributes({ commit }, attributes: object) {
        commit('SET_ATTRIBUTES', attributes)
    },
    appendOccupant({ commit }, occupant) {
        commit('APPEND_OCCUPANT', { occupant })
    },
    removeOccupant({ commit }, occupant) {
        commit('REMOVE_OCCUPANT', { occupant })
    },
    appendRoomSelection({ commit }, room) {
        commit('APPEND_ROOM_SELECTION', { room })
    },
    removeRoomSelection({ commit }, room) {
        commit('REMOVE_ROOM_SELECTION', { room })
    },
    async loadAccountProfile({ commit, rootGetters }) {
        if (!rootGetters['isAuthenticated']) {
            return
        }
        const profile = await AccountService.getAccountProfile()
        commit('SET_ATTRIBUTES', {
            firstName: profile.firstName,
            lastName: profile.lastName,
            address: profile.address,
            phoneNumber: profile.phoneNumber,
            budget: profile.budget,
            dwellingType: profile.dwellingType,
            propertyType: profile.propertyType,
            redesignReason: profile.redesignReason,
            timeline: profile.timeline,
        })
        profile.occupants.forEach(occupant => commit('APPEND_OCCUPANT', { occupant }))
        profile.rooms.forEach(room => commit('APPEND_ROOM_SELECTION', { room }))
        commit('SET_ACCOUNT_PROFILE', { profile }, { root: true })
        commit('CLEAR_DIRTY')
    },
    async saveAccountProfile({ commit, getters, rootGetters }) {
        if (!rootGetters['isAuthenticated']) {
            return
        }
        if (getters.$dirty.length === 0) {
            return
        }
        const request = getters.$dirty.reduce((acc, k) => {
            acc[k] = getters[k];
            return acc
        }, {})
        const profile = await AccountService.patchAccountProfile(<PatchAccountProfileRequest>request)
        commit('SET_ACCOUNT_PROFILE', { profile }, { root: true })
        commit('CLEAR_DIRTY')
    },
    async registerAccount({ dispatch, getters }) {
        await AccountService.registerCustomerAccount({
            email: getters.emailAddress,
            password: getters.password,
            firstName: getters.firstName,
            lastName: '',
        })
        await dispatch('loadCurrentUser', null, { root: true })
    },
}

const mutations: Mutations = {
    SET_ATTRIBUTES(state, payload) {
        for (const [ k, v ] of Object.entries(payload)) {
            state.$dirty.add(k)
            if (isObject(state[k])) {
                merge(state[k], v)
                continue
            }
            state[k] = v
        }
    },
    CLEAR_DIRTY(state) {
        state.$dirty.clear()
    },
    APPEND_OCCUPANT(state, { occupant }) {
        state.$dirty.add('occupants')
        state.occupants.add(occupant)
    },
    REMOVE_OCCUPANT(state, { occupant }) {
        state.$dirty.add('occupants')
        state.occupants.delete(occupant)
    },
    APPEND_ROOM_SELECTION(state, { room }) {
        state.$dirty.add('rooms')
        state.rooms.add(room)
    },
    REMOVE_ROOM_SELECTION(state, { room }) {
        state.$dirty.add('rooms')
        state.rooms.delete(room)
    },
}

const getters: Getters = {
    $dirty(state) {
        return Array.from(state.$dirty.values())
    },
    firstName(state) {
        return state.firstName
    },
    lastName(state) {
        return state.lastName
    },
    emailAddress(state) {
        return state.emailAddress
    },
    password(state) {
        return state.password
    },
    address(state) {
        return state.address
    },
    phoneNumber(state) {
        return state.phoneNumber
    },
    budget(state) {
        return state.budget
    },
    dwellingType(state) {
        return state.dwellingType
    },
    occupants(state) {
        return Array.from(state.occupants.values())
    },
    propertyType(state) {
        return state.propertyType
    },
    redesignReason(state) {
        return state.redesignReason
    },
    rooms(state) {
        return Array.from(state.rooms.values())
    },
    timeline(state) {
        return state.timeline
    },
}

export default { state, actions, getters, mutations }
