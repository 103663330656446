import UrlInvalidError from '@/errors/UrlInvalidError'
import api from '@/helpers/_api'
import { ProductSearchRequest } from '@/models/api/request/ProductSearchRequest'
import { ProductResource } from '@/models/api/resource/ProductResource'

export default class ProductService {

    static async searchProducts(params: ProductSearchRequest): Promise<ProductResource[]> {
        return <ProductResource[]>(await api.get(`products/search`, { params })).data.data
    }

    static sanitizeUrl(url: string): string {
        try {
            const _url = new URL(url)
            const params = ProductService.filterQueryParams(_url)
            return ''.concat(_url.origin, _url.pathname, params)
        }
        catch (err: any) {
            throw new UrlInvalidError('URL could not be parsed')
        }
    }

    static filterQueryParams(url: URL, allowedParams: string[] = []): string {
        const filteredParams = new URLSearchParams()
        for (const [ k, v ] of url.searchParams) {
            if (allowedParams.includes(k)) {
                filteredParams.set(k, v)
            }
        }
        return filteredParams.toString() ? '?' + filteredParams.toString() : ''
    }
}
