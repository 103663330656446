import { FooterMode } from '@/enums/FooterMode'
import { HeaderMode } from '@/enums/HeaderMode'
import { RoleName } from '@/enums/RoleName'
import { appConfig } from '@/helpers/_config'
import filters from '@/helpers/_filters'
import { ProjectStage } from '@/models/api/resource/ProjectResource'
import PageService from '@/services/PageService'
import ProjectService from '@/services/ProjectService'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import propsParser from 'vue-router-parse-props'
import store from '../store/index'

export enum RouteName {
    ACCOUNT_DESIGN_STYLE = 'AccountDesignStyle',
    ACCOUNT_PAYMENT = 'AccountPayment',
    ACCOUNT_PROFILE = 'AccountProfile',
    ACCOUNT_SETTINGS = 'AccountSettings',
    ACCOUNT_TRANSACTIONS = 'AccountTransactions',
    DESIGNERS = 'Designers',
    DESIGNER_PROFILE = 'DesignerProfile',
    FIRST_LOOK_DASHBOARD = 'FirstLookDashboard',
    GIFT_VOUCHER = 'GiftVoucher',
    INVITE_DASHBOARD = 'InviteDashboard',
    LOGIN = 'Login',
    FORGOT_PASSWORD = 'ForgotPassword',
    RESET_PASSWORD = 'ResetPassword',
    CREATE_PROJECT = 'CreateProject',
    PROJECT_DASHBOARD = 'ProjectDashboard',
    PROJECT_INTRO_CALL = 'ProjectIntroCall',
    PROJECT_OVERVIEW = 'ProjectOverview',
    PROJECT_PACKAGE = 'ProjectPackage',
    PROVIDE_PROJECT_FEEDBACK = 'ProvideProjectFeedback',
    STYLE_QUIZ = 'StyleQuiz',
    STYLE_RESULT = 'StyleResult',
    WELCOME_WIZARD = 'WelcomeWizard',
}

export class NamedRoute {
    readonly name: string
    readonly params: object

    constructor(name: string, params?: object) {
        this.name = name
        this.params = params
    }
}

export class ExternalRoute {
    readonly _url: string
    readonly isApp: boolean

    constructor(url: string, isApp: boolean = false) {
        this._url = url
        this.isApp = isApp
    }

    get url(): string {
        if (this.isApp) {
            return ''.concat(appConfig.contextPath, this._url)
        }
        return this._url
    }
}

function appRoutes(routes) {
    const prefix = appConfig.contextPath
    return routes.map(route => {
        route.path = prefix + '/' + route.path
        route.meta = Object.assign({
            headerMode: HeaderMode.APPLICATION,
            footerMode: FooterMode.APPLICATION,
        }, (route.meta ?? {}))
        return route
    })
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/designers',
        redirect: { name: RouteName.DESIGNERS },
    },
    {
        path: '/designers/:designerSlug',
        redirect: to => ({ name: RouteName.DESIGNER_PROFILE, params: { designerSlug: to.params['designerSlug'] } }),
    },
    {
        path: '/interior-designers',
        name: RouteName.DESIGNERS,
        meta: {
            serverMode: true,
            publicMode: true,
            headerMode: HeaderMode.MARKETING,
            footerMode: FooterMode.MARKETING,
            title: 'Our Designers',
        },
        component: () => import('../views/Designers.vue'),
    },
    {
        path: '/interior-designers/:designerSlug',
        name: RouteName.DESIGNER_PROFILE,
        meta: {
            serverMode: true,
            publicMode: true,
            headerMode: HeaderMode.MARKETING,
            footerMode: FooterMode.MARKETING,
            title: 'Our Designers',
        },
        component: () => import('../views/DesignerProfile.vue'),
    },
    ...appRoutes([
        {
            path: 'login',
            name: RouteName.LOGIN,
            meta: {
                serverMode: true,
                publicMode: true,
                headerMode: HeaderMode.MARKETING,
                headerHideNavItems: true,
                headerHideActionItems: true,
                headerHideMobileToggle: true,
                footerMode: FooterMode.OFF,
                title: 'Login',
            },
            component: () => import('../views/Login.vue'),
            beforeEnter: (to, from, next) => {
                const isAuthenticated = store.getters['isAuthenticated']
                const isAdministrator = store.getters['isAdministrator']
                if (isAuthenticated && !isAdministrator) {
                    window.location.href = filters.url('dashboard')
                    return
                }
                return next()
            },
        },
        {
            path: 'forgot-password',
            name: RouteName.FORGOT_PASSWORD,
            meta: {
                serverMode: true,
                publicMode: true,
                headerMode: HeaderMode.MARKETING,
                headerHideNavItems: true,
                headerHideActionItems: true,
                headerHideMobileToggle: true,
                footerMode: FooterMode.OFF,
                title: 'Password Reset',
            },
            component: () => import('../views/ForgotPassword.vue'),
            beforeEnter: (to, from, next) => {
                const isAuthenticated = store.getters['isAuthenticated']
                const isAdministrator = store.getters['isAdministrator']
                if (isAuthenticated && !isAdministrator) {
                    window.location.href = filters.url('dashboard')
                    return
                }
                return next()
            },
        },
        {
            path: 'reset-password/:token',
            name: RouteName.RESET_PASSWORD,
            meta: {
                serverMode: true,
                publicMode: true,
                headerMode: HeaderMode.MARKETING,
                headerHideNavItems: true,
                headerHideActionItems: true,
                headerHideMobileToggle: true,
                footerMode: FooterMode.OFF,
                title: 'Change Password',
            },
            component: () => import('../views/ResetPassword.vue'),
            beforeEnter: (to, from, next) => {
                const isAuthenticated = store.getters['isAuthenticated']
                const isAdministrator = store.getters['isAdministrator']
                if (isAuthenticated && !isAdministrator) {
                    window.location.href = filters.url('dashboard')
                    return
                }
                return next()
            },
        },
        {
            path: 'hello',
            name: RouteName.WELCOME_WIZARD,
            meta: {
                serverMode: true,
                publicMode: true,
            },
            component: () => import('../views/WelcomeWizard.vue'),
            beforeEnter: async (to, from, next) => {
                if (to.name === RouteName.WELCOME_WIZARD) {
                    return next({ name: RouteName.STYLE_QUIZ })
                }
                return next()
            },
            children: [
                {
                    path: 'style-quiz',
                    name: RouteName.STYLE_QUIZ,
                    meta: {
                        publicMode: true,
                        appCustomClass: 'pt-10 pt-sm-16',
                        headerCustomClass: 'd-none d-sm-block',
                        headerCenterFallbackLogo: true,
                        headerHideActionItems: true,
                        headerHideNavItems: true,
                        headerHideMobileToggle: true,
                        headlineHide: true,
                        footerMode: FooterMode.OFF,
                        roles: [
                            RoleName.GUEST,
                            RoleName.ADMINISTRATOR,
                            RoleName.CUSTOMER,
                        ],
                    },
                    component: () => import('../views/WelcomeWizard/StyleSelection.vue'),
                },
                {
                    path: 'style-result',
                    name: RouteName.STYLE_RESULT,
                    meta: {
                        publicMode: true,
                        appCustomClass: 'pt-10 pt-sm-16',
                        headerCustomClass: 'd-none d-sm-block',
                        headerCenterFallbackLogo: true,
                        headerHideActionItems: true,
                        headerHideNavItems: true,
                        headerHideMobileToggle: true,
                        headlineHide: true,
                        footerMode: FooterMode.OFF,
                        roles: [
                            RoleName.GUEST,
                            RoleName.ADMINISTRATOR,
                            RoleName.CUSTOMER
                        ],
                    },
                    component: () => import('../views/WelcomeWizard/StyleResult.vue'),
                },
            ],
        },
        {
            path: 'gifts',
            name: RouteName.GIFT_VOUCHER,
            meta: {
                serverMode: true,
                publicMode: true,
                title: 'Gifts',
            },
            component: () => import('../views/GiftVoucher.vue'),
        },
        {
            path: 'dashboard',
            name: RouteName.PROJECT_DASHBOARD,
            meta: { title: 'Dashboard' },
            component: () => import('../views/ProjectDashboard.vue'),
            beforeEnter: async (to, from, next) => {
                const isAdministrator = store.getters['isAdministrator']
                if (isAdministrator) {
                    window.location.href = filters.url('admin/dashboard')
                    return next()
                }
                return next()
            },
        },
        {
            path: 'dashboard/first-looks',
            name: RouteName.FIRST_LOOK_DASHBOARD,
            meta: {
                title: 'Dashboard - First Looks',
                roles: [ RoleName.DESIGNER ],
            },
            component: () => import('../views/FirstLookDashboard.vue'),
        },
        {
            path: 'dashboard/invitations',
            name: RouteName.INVITE_DASHBOARD,
            meta: {
                title: 'Dashboard - Invitations',
                roles: [ RoleName.DESIGNER ],
            },
            component: () => import('../views/InviteDashboard.vue'),
        },
        {
            path: 'settings/account',
            name: RouteName.ACCOUNT_SETTINGS,
            component: () => import('../views/AccountSettings.vue'),
        },
        {
            path: 'settings/design-style',
            name: RouteName.ACCOUNT_DESIGN_STYLE,
            meta: {
                roles: [ RoleName.CUSTOMER ],
            },
            component: () => import('../views/AccountDesignStyle.vue'),
        },
        {
            path: 'settings/payment',
            name: RouteName.ACCOUNT_PAYMENT,
            component: () => import('../views/AccountPayment.vue'),
        },
        {
            path: 'settings/profile',
            name: RouteName.ACCOUNT_PROFILE,
            component: () => import('../views/AccountProfile.vue'),
        },
        {
            path: 'settings/receipts',
            name: RouteName.ACCOUNT_TRANSACTIONS,
            component: () => import('../views/AccountTransactions.vue'),
        },
        {
            path: 'projects/create',
            name: RouteName.CREATE_PROJECT,
            meta: {
                roles: [ RoleName.CUSTOMER ],
            },
            component: () => import('../views/CreateProject.vue'),
            beforeEnter: async (to, from, next) => {
                const user = store.getters['currentUser']
                if (user.role === 'customer' && user.customer.isProfileComplete) {
                    return next()
                }
                window.location.href = filters.url('dashboard')
            },
        },
        {
            path: 'projects/:projectId',
            name: RouteName.PROJECT_OVERVIEW,
            props: propsParser({ projectId: Number }),
            component: () => import('../views/ProjectOverview.vue'),
            beforeEnter: async (to, from, next) => {
                try {
                    const project = await store.dispatch('loadProject', to.params.projectId)
                    await store.dispatch('setActiveProjectId', { projectId: project.id })
                    if (project.stage === ProjectStage.AWAITING_FIRST_LOOK && !project.brief.isAppointmentBypassed && !project.brief.isAppointmentCompleted) {
                        return next({ name: RouteName.PROJECT_INTRO_CALL, params: { projectId: project.id } })
                    }
                    if (store.getters['isDesigner'] && project.stage === ProjectStage.AWAITING_FIRST_LOOK && (project.brief.isAppointmentBypassed || project.brief.isAppointmentCompleted)) {
                        const firstLookId = project.firstLooks.filter(f => !f.publishedAt)[0]?.id
                        if (firstLookId) {
                            window.location.href = filters.url(`projects/${to.params.projectId}/firstlooks/${firstLookId}/edit`)
                            return
                        }
                    }
                    return next()
                }
                catch (err) {
                    return next(err)
                }
            },
        },
        {
            path: 'projects/:projectId/intro-call',
            name: RouteName.PROJECT_INTRO_CALL,
            props: propsParser({ projectId: Number }),
            component: () => import('../views/ProjectIntroCall.vue'),
            beforeEnter: async (to, from, next) => {
                try {
                    const projectId = parseInt(<string>to.params.projectId)
                    await store.dispatch('loadProject', projectId)
                    await store.dispatch('setActiveProjectId', { projectId })
                    if (store.getters['project'].stage !== ProjectStage.AWAITING_FIRST_LOOK || store.getters['project'].brief.isAppointmentBypassed || store.getters['project'].brief.isAppointmentCompleted) {
                        return next({ name: RouteName.PROJECT_OVERVIEW, params: { projectId } })
                    }
                    return next()
                }
                catch (err) {
                    return next(err)
                }
            },
        },
        {
            path: 'projects/:projectId/package',
            name: RouteName.PROJECT_PACKAGE,
            props: propsParser({ projectId: Number }),
            component: () => import('../views/ProjectPackage.vue'),
            beforeEnter: async (to, from, next) => {
                try {
                    const projectId = parseInt(<string>to.params.projectId)
                    await store.dispatch('loadProject', projectId)
                    await store.dispatch('setActiveProjectId', { projectId })
                    await ProjectService.validatePackageIsAccessible(projectId)
                    return next()
                }
                catch (err) {
                    return next(err)
                }
            },
        },
        {
            path: 'projects/:projectId/feedback',
            name: RouteName.PROVIDE_PROJECT_FEEDBACK,
            meta: {
                headerMode: HeaderMode.OFF,
                footerMode: FooterMode.OFF,
                roles: [ RoleName.CUSTOMER ],
            },
            props: propsParser({ projectId: Number }),
            component: () => import('../views/ProvideProjectFeedback.vue'),
            beforeEnter: async (to, from, next) => {
                try {
                    const projectId = parseInt(<string>to.params.projectId)
                    await store.dispatch('loadProject', projectId)
                    await store.dispatch('setActiveProjectId', { projectId })
                    await ProjectService.validateProjectIsAccessible(projectId)
                    return next()
                }
                catch (err) {
                    return next(err)
                }
            },
        },
    ]),
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeEach(async (to) => {
    if (!store.getters.isAuthenticated) {
        await store.dispatch('loadCurrentUser')
    }
    if (!store.getters.isAuthenticated && !to.meta?.publicMode) {
        await router.push({ name: RouteName.LOGIN })
        return
    }
    const roles = <string[]>to.meta.roles
    if (!store.getters.isAuthenticated && roles && roles.includes(RoleName.GUEST)) {
        return
    }
    const currentUser = store.getters.currentUser
    if (roles && !roles.includes(currentUser?.role)) {
        await router.push({ name: RouteName.PROJECT_DASHBOARD })
        return
    }
})

router.afterEach((to) => {
    store.dispatch('toggleGlobalError', false)
    PageService.removeMetaTag('description')
    PageService.removeMetaTag('keywords')
    PageService.removeLinkTag('canonical')
    if (to.meta?.title) {
        document.title = <string>to.meta.title + ' | Milray Park'
    }
    if (to.meta?.serverMode) {
        (async () => {
            const data = await PageService.getData()
            PageService.setTitle(data.title)
            if (data.desc) PageService.setMetaTag('description', data.desc)
            if (data.keywords) PageService.setMetaTag('keywords', data.keywords)
            if (data.canonical) PageService.setLinkTag('canonical', data.canonical)
        })()
    }
})

router.onError(() => {
    // window.location.href = filters.url('dashboard')
})

export default router
