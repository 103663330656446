import { ActivityResource } from '@/models/api/resource/ActivityResource'
import { BriefResource } from '@/models/api/resource/BriefResource'
import { DesignerResource } from '@/models/api/resource/DesignerResource'
import { PlanResource } from '@/models/api/resource/PlanResource'
import { CustomerProfileResource } from '@/models/api/resource/ProfileResource'
import { ProjectFeedbackResource } from '@/models/api/resource/ProjectFeedbackResource'
import { PackageResource } from '@/models/api/resource/ProjectPackageResource'

export enum ProjectStage {
    PROJECT_BRIEF       = 1,
    INVITE_DESIGNERS    = 2,
    AWAITING_FIRST_LOOK = 3,
    DESIGN_PHASE        = 4,
    COMPLETE            = 5,
}

export interface ProjectResource {
    id: number
    createdAt: Date
    updatedAt: Date
    upgradedAt: Date
    closedAt: Date
    title: string
    roomName: string
    stage: ProjectStage
    customer: CustomerProfileResource
    designer: DesignerResource
    plan: PlanResource
    brief: BriefResource
    firstLooks
    package: PackageResource
    feedback: ProjectFeedbackResource
    activities: ActivityResource[]
    shortlistRequested: boolean
}
