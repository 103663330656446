import { appConfig } from '@/helpers/_config'

export default {

    url(val: string): string {
        return appConfig.contextPath + '/' + val
    },

    formatPrice(val: number): string {
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'AUD',
            currencyDisplay: 'narrowSymbol'
        }).format(val).replace('$', '')
    },
}
