import api from '@/helpers/_api'
import { apiConfig } from '@/helpers/_config'
import { UpdatePageSettingsRequest } from '@/models/api/request/UpdatePageSettingsRequest'
import { PageResource } from '@/models/api/resource/PageResource'
import trimStart from 'lodash/trimStart'
import { DateTime } from 'luxon'

export default class PageService {

    static async getPageSettings(): Promise<PageResource> {
        const url = (() => {
            const currentUrl = window.location.href
            const path = trimStart(currentUrl.replace(apiConfig.baseUrl, ''), '/')
            return `pages/${path}`
        })()
        const response = await api.get(url)
        const resource = response.data.data
        resource.createdAt = DateTime.fromISO(resource.createdAt)
        resource.updatedAt = DateTime.fromISO(resource.updatedAt)
        return response.data.data
    }

    static async updatePageSettings(request: UpdatePageSettingsRequest): Promise<PageResource> {
        const url = (() => {
            const currentUrl = window.location.href
            const path = trimStart(currentUrl.replace(apiConfig.baseUrl, ''), '/')
            return `pages/${path}`
        })()
        const response = await api.put(url, request)
        const resource = response.data.data
        resource.createdAt = DateTime.fromISO(resource.createdAt)
        resource.updatedAt = DateTime.fromISO(resource.updatedAt)
        return response.data.data
    }

    static async getData(): Promise<Partial<PageResource> & { canonical?: string }> {
        const url = (() => {
            const _url = new URL(window.location.href)
            // Set query parameter as current timestamp to prevent local caching, otherwise viewing source will return
            // the cached JSON version due to having the same URL.
            _url.searchParams.set('v', DateTime.now().toUnixInteger().toString())
            return _url.toString()
        })()
        const response = await api.get(url, {
            data: {},
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        return response.data.data
    }

    static setTitle(title: string): void {
        document.title = title
    }

    static setLinkTag(rel: string, href: string): void {
        const el = document.querySelector(`link[rel=${rel}]`) ?? document.createElement('link')
        el.setAttribute('rel', rel)
        el.setAttribute('href', href)
        document.head.append(el)
    }

    static removeLinkTag(rel: string): void {
        const el = document.querySelector(`link[rel=${rel}]`)
        if (el) document.head.removeChild(el)
    }

    static setMetaTag(name: string, content: string): void {
        const el = document.querySelector(`meta[name=${name}]`) ?? document.createElement('meta')
        el.setAttribute('name', name)
        el.setAttribute('content', content)
        document.head.append(el)
    }

    static removeMetaTag(name: string): void {
        const el = document.querySelector(`meta[name=${name}]`)
        if (el) document.head.removeChild(el)
    }
}
