import api from '@/helpers/_api'
import { CartItemRequest } from '@/models/api/request/CartItemRequest'
import { CartRequest } from '@/models/api/request/CartRequest'
import { CartResource } from '@/models/api/resource/CartResource'
import { Cart } from '@/models/Cart'

export default class CartService {

    static async calculateCart(cart: Cart): Promise<CartResource> {
        try {
            return <CartResource>(await api.post(`cart/calculate`, CartService.prepareCartPayload(cart))).data.data
        }
        catch (err: any) {
            if (err.response?.data?.error === 'CouponExpiredException') {
                throw new Error('The promocode has expired')
            }
            throw new Error('The promocode is not valid')
        }
    }

    static async processCart(cart: Cart): Promise<CartResource> {
        return <CartResource>(await api.post(`cart`, CartService.prepareCartPayload(cart))).data.data
    }

    private static prepareCartPayload(cart: Cart): CartRequest {
        return {
            items: CartService.prepareCartItemsWithPlan(cart),
            couponCode: cart.couponCode,
            giftCode: cart.giftCode,
        }
    }

    private static prepareCartItemsWithPlan(cart: Cart): CartItemRequest[] {
        return <CartItemRequest[]>cart.items.filter(i => i.isCommitted)
    }
}
