import { AppointmentResource } from '@/models/api/resource/AppointmentResource'
import { AvailabilityResource } from '@/models/api/resource/AvailabilityResource'
import { MeetingRoomResource } from '@/models/api/resource/MeetingRoomResource'
import BriefService from '@/services/BriefService'
import { BaseActions } from '@/store/BaseActions'
import { Interval } from 'luxon'

type State = {
    availabilities: { [projectId: number]: AvailabilityResource[] }
    appointments: { [projectId: number]: AppointmentResource }
}

type Actions = {
    loadAppointment(payload: { projectId?: number }): Promise<void>
    loadAvailabilities(payload: { projectId?: number }): Promise<void>
    bypassAppointment(payload: { projectId?: string }): Promise<void>
    createAppointment(payload: { projectId?: number, appointment: Interval }): Promise<void>
    createAvailabilities(payload: { projectId?: number, availabilities: Interval[] }): Promise<void>
    createMeetingRoomAttendance(payload: { projectId?: number }): Promise<void>
    deleteAppointment(payload: { projectId?: number }): Promise<void>
    rejectAllAvailabilities(payload: { projectId?: number }): Promise<void>
}

type Mutations = {
    UPDATE_APPOINTMENT(state: State, payload: { projectId: number, resource: AppointmentResource }): void
    UPDATE_AVAILABILITIES(state: State, payload: { projectId: number, resources: AvailabilityResource[] }): void
    DELETE_APPOINTMENT(state: State, payload: { projectId: number }): void
    DELETE_AVAILABILITIES(state: State, payload: { projectId: number }): void
}

type Getters = {
    appointment(state: State, getters: Getters, rootState: any): Interval
    availabilities(state: State, getters: Getters, rootState: any): Interval[]
    meetingRoom(state: State, getters: Getters, rootState: any): MeetingRoomResource
}

const state: State = {
    appointments: {},
    availabilities: {},
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadAppointment({ commit, rootGetters }, payload = {}) {
        const projectId = payload.projectId ?? rootGetters['activeProjectId']
        const resource = await BriefService.getAppointment(projectId) ?? null
        commit('UPDATE_APPOINTMENT', { projectId, resource })
    },
    async loadAvailabilities({ commit, rootGetters }, payload = {}) {
        const projectId = payload.projectId ?? rootGetters['activeProjectId']
        const resources = await BriefService.getAvailabilities(projectId)
        commit('UPDATE_AVAILABILITIES', { projectId, resources })
    },
    async bypassAppointment({ commit, rootGetters }, payload): Promise<void> {
        const projectId = payload?.projectId ?? rootGetters['activeProjectId']
        const resource = await BriefService.bypassAppointment(projectId)
        commit('UPDATE_BRIEF', { projectId, resource }, { root: true })
    },
    async createAppointment({ commit, rootGetters }, payload): Promise<void> {
        const projectId = payload?.projectId ?? rootGetters['activeProjectId']
        const request = {
            startTime: payload.appointment.start,
            endTime: payload.appointment.end,
        }
        const resource = await BriefService.createAppointment(projectId, request)
        commit('UPDATE_APPOINTMENT', { projectId, resource })
    },
    async createAvailabilities({ commit, rootGetters }, payload): Promise<void> {
        const projectId = payload?.projectId ?? rootGetters['activeProjectId']
        const request = payload.availabilities.map(i => ({
            startTime: i.start,
            endTime: i.end,
        }))
        const resources = await BriefService.createAvailabilities(projectId, request)
        commit('UPDATE_AVAILABILITIES', { projectId, resources })
    },
    async createMeetingRoomAttendance({ rootGetters }, payload = {}): Promise<void> {
        const projectId = payload.projectId ?? rootGetters['activeProjectId']
        await BriefService.createMeetingRoomAttendance(projectId)
    },
    async deleteAppointment({ commit, dispatch, rootGetters }, payload = {}): Promise<void> {
        const projectId = payload.projectId ?? rootGetters['activeProjectId']
        await BriefService.deleteAppointment(projectId)
        dispatch('loadBrief', { reload: true }, { root: true })
        commit('DELETE_APPOINTMENT', { projectId })
    },
    async rejectAllAvailabilities({ commit, dispatch, rootGetters }, payload = {}): Promise<void> {
        const projectId = payload.projectId ?? rootGetters['activeProjectId']
        await BriefService.rejectAllAvailabilities(projectId)
        dispatch('loadBrief', { reload: true }, { root: true })
        commit('DELETE_AVAILABILITIES', { projectId })
    },
}

const mutations: Mutations = {
    UPDATE_APPOINTMENT(state, { projectId, resource }) {
        state.appointments[projectId] = resource
    },
    UPDATE_AVAILABILITIES(state, { projectId, resources }) {
        state.availabilities[projectId] = resources
    },
    DELETE_APPOINTMENT(state, { projectId }) {
        delete state.appointments[projectId]
    },
    DELETE_AVAILABILITIES(state, { projectId }) {
        delete state.availabilities[projectId]
    },
}

const getters: Getters = {
    appointment(state, getters, rootState) {
        const projectId = rootState.project.activeProjectId
        if (!state.appointments[projectId]) {
            return null
        }
        const start = state.appointments[projectId].startTime
        const end = state.appointments[projectId].endTime
        return Interval.fromDateTimes(start, end)
    },
    availabilities(state, getters, rootState) {
        const projectId = rootState.project.activeProjectId
        return state.availabilities[projectId]?.map((a: AvailabilityResource) => {
            const start = a.startTime
            const end = a.endTime
            return Interval.fromDateTimes(start, end)
        }) ?? []
    },
    meetingRoom(state, getters, rootState): MeetingRoomResource {
        const projectId = rootState.project.activeProjectId
        return state.appointments[projectId].meetingRoom
    },
}

export default { state, actions, getters, mutations }
