import echo from '@/helpers/broadcast'
import { ChatMessageResource } from '@/models/api/resource/ChatMessageResource'
import { ChatSessionResource } from '@/models/api/resource/ChatSessionResource'
import ChatService from '@/services/ChatService'
import { BaseActions } from '@/store/BaseActions'
import { DateTime } from 'luxon'

type State = {
    isWindowExpanded: boolean
    session: ChatSessionResource
    messages: ChatMessageResource[]
    unreadNotificationCount: number
}

type Actions = {
    toggleWindow(): void
    purgeSession(): void
    purgeMessages(): void
    purgeUnreadNotifications(): void
    loadSession(): Promise<void>
    loadMessages(): Promise<void>
    loadUnreadNotifications(): Promise<void>
    flagUnreadNotificationsAsRead(): Promise<void>
    createMessage(payload: { message: string }): Promise<void>
    subscribeChannel(): void
    unsubscribeChannel(): void
}

type Mutations = {
    TOGGLE_WINDOW(state: State): void
    MINIMIZE_WINDOW(state: State): void
    PURGE_SESSION(state: State): void
    SET_SESSION(state: State, payload: { resource: ChatSessionResource }): void
    PURGE_MESSAGES(state: State): void
    APPEND_MESSAGE(state: State, payload: { resource: ChatMessageResource }): void
    PURGE_UNREAD_NOTIFICATION_COUNT(state: State)
    SET_UNREAD_NOTIFICATION_COUNT(state: State, { count: number }): void
    INCREMENT_UNREAD_NOTIFICATION_COUNT(state: State): void
}

type Getters = {
    isWindowExpanded(state: State): boolean
    session(state: State): ChatSessionResource
    messages(state: State): ChatMessageResource[]
    unreadNotificationCount(state: State): number
}

const state: State = {
    isWindowExpanded: false,
    session: null,
    messages: [],
    unreadNotificationCount: 0,
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    toggleWindow({ commit, dispatch, getters }) {
        commit('TOGGLE_WINDOW')
        if (getters.isWindowExpanded && getters.unreadNotificationCount > 0) {
            dispatch('flagUnreadNotificationsAsRead')
        }
    },
    purgeSession({ commit }) {
        commit('PURGE_SESSION')
        commit('MINIMIZE_WINDOW')
    },
    purgeMessages({ commit }) {
        commit('PURGE_MESSAGES')
    },
    purgeUnreadNotifications({ commit }) {
        commit('PURGE_UNREAD_NOTIFICATION_COUNT')
    },
    async loadSession({ commit, state, rootGetters }) {
        if (state.session) {
            commit('PURGE_SESSION')
        }
        const projectId = rootGetters['activeProjectId']
        const resource = await ChatService.getSession(projectId)
        commit('SET_SESSION', { resource })
    },
    async loadMessages({ commit, rootGetters }) {
        if (state.messages.length > 0) {
            commit('PURGE_MESSAGES')
        }
        const projectId = rootGetters['activeProjectId']
        const resources = await ChatService.getMessages(projectId)
        for (const resource of resources) {
            commit('APPEND_MESSAGE', { resource })
        }
    },
    async loadUnreadNotifications({ commit, rootGetters }) {
        const projectId = rootGetters['activeProjectId']
        const resources = await ChatService.getUnreadNotifications(projectId)
        commit('SET_UNREAD_NOTIFICATION_COUNT', { count: resources.length })
    },
    async flagUnreadNotificationsAsRead({ commit, rootGetters }): Promise<void> {
        const projectId = rootGetters['activeProjectId']
        await ChatService.flagUnreadNotificationsAsRead(projectId)
        commit('PURGE_UNREAD_NOTIFICATION_COUNT')
    },
    async createMessage({ commit, rootGetters }, { message }) {
        const projectId = rootGetters['activeProjectId']
        const resource = await ChatService.createMessage(projectId, { message })
        commit('APPEND_MESSAGE', { resource })
    },
    async subscribeChannel({ commit, dispatch, getters }) {
        (await echo()).private(getters.session.channelName).listen('ChatMessageWasSent', (resource) => {
            resource.createdAt = DateTime.fromISO(resource.createdAt)
            commit('APPEND_MESSAGE', { resource })
            if (getters.isWindowExpanded) {
                dispatch('flagUnreadNotificationsAsRead')
            }
            if (!getters.isWindowExpanded) {
                commit('INCREMENT_UNREAD_NOTIFICATION_COUNT')
            }
        })
    },
    async unsubscribeChannel({ getters }) {
        (await echo()).leave(getters.session.channelName)
    },
}

const mutations: Mutations = {
    TOGGLE_WINDOW(state) {
        state.isWindowExpanded = !state.isWindowExpanded
    },
    MINIMIZE_WINDOW(state) {
        state.isWindowExpanded = false
    },
    PURGE_SESSION(state) {
        state.session = null
    },
    PURGE_MESSAGES(state) {
        state.messages.length = 0
    },
    SET_SESSION(state, payload) {
        state.session = payload.resource
    },
    APPEND_MESSAGE(state, { resource }) {
        if (state.messages.find(m => m.id === resource.id)) {
            return
        }
        state.messages.push(resource)
    },
    PURGE_UNREAD_NOTIFICATION_COUNT(state) {
        state.unreadNotificationCount = 0
    },
    SET_UNREAD_NOTIFICATION_COUNT(state, { count }) {
        state.unreadNotificationCount = count
    },
    INCREMENT_UNREAD_NOTIFICATION_COUNT(state) {
        state.unreadNotificationCount++
    },
}

const getters: Getters = {
    isWindowExpanded(state) {
        return state.isWindowExpanded
    },
    session(state) {
        return state.session
    },
    messages(state) {
        return state.messages
    },
    unreadNotificationCount(state) {
        return state.unreadNotificationCount
    },
}

export default { state, actions, mutations, getters }
