import { ProductSearchRequest } from '@/models/api/request/ProductSearchRequest'
import { ProductResource } from '@/models/api/resource/ProductResource'
import ProductService from '@/services/ProductService'
import { BaseActions } from '@/store/BaseActions'

export declare const products: State

export declare function searchProducts(params: ProductSearchRequest): Promise<ProductResource|null>

type State = typeof state

type Actions = {
    searchProducts: typeof searchProducts
}

type Getters = {
    products(state: State): typeof products
}

type Mutations = {
    PUSH_PRODUCT(state: State, payload: { productResource: ProductResource }): void
}

const state: ProductResource[] = []

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async searchProducts({ commit, getters }, params): Promise<ProductResource|null> {
        const url = ProductService.sanitizeUrl(params.url)
        const product = getters.products.find(p => p.url === url)
        if (product) {
            return product
        }
        const productResource = (await ProductService.searchProducts({ url }))[0] ?? null
        if (!productResource) {
            return null
        }
        commit('PUSH_PRODUCT', { productResource })
        return productResource
    },
}

const getters: Getters = {
    products(state) {
        return state
    },
}

const mutations: Mutations = {
    PUSH_PRODUCT(state, { productResource }) {
        const product = state.find(p => p.id === productResource.id)
        if (product) {
            Object.assign(product, productResource)
            return
        }
        state.push(productResource)
    }
}

export default { state, actions, getters, mutations }
