import { apiConfig } from '@/helpers/_config'
import { AxiosInstance, default as axios } from 'axios'

export const options = {
    baseURL: apiConfig.apiBaseUrl,
    headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
}

const instance = axios.create(options)

export function createAxios(): AxiosInstance {
    instance.defaults.withCredentials = true
    instance.interceptors.response.use(
        (res) => {
            return res
        },
        (err) => {
            if (err.response.status === 401) {
                window.location.href = '/auth/login'
                return
            }
            throw err
        },
    )
    return instance
}

export default instance
