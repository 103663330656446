import { broadcastConfig } from '@/helpers/_config'

let _echo = null

const options = {
    authEndpoint: broadcastConfig.authEndpoint,
    broadcaster: broadcastConfig.service,
    cluster: broadcastConfig.cluster,
    key: broadcastConfig.key,
}

export default async function echo(): Promise<any> {
    if (options.key) {
        const Echo = (await import('laravel-echo')).default
        const Pusher = (await import('pusher-js')).default
        _echo = new Echo({
            ...options,
            client: new Pusher(options.key, options),
        })
        return _echo
    }
    return null
}
