export default class FormService {

    public static convertJsonToFormData(params: Object): FormData {
        const formData = new FormData()
        for (const k of Object.keys(params)) {
            if (params[k] === null) {
                formData.append(k, '')
                continue
            }
            if (params[k] instanceof File) {
                formData.append(k, params[k], params[k].name)
                continue
            }
            if (typeof params[k] === 'boolean') {
                formData.append(k, params[k] ? '1' : '0')
                continue
            }
            if (typeof params[k] === 'object') {
                formData.append(k, JSON.stringify(params[k]))
                continue
            }
            formData.append(k, params[k])
        }
        return formData
    }
}
