import { RoomTypeResource } from '@/models/api/resource/RoomTypeResource'
import { RoomType } from '@/models/RoomType'
import RoomService from '@/services/RoomService'
import { BaseContext } from '@/store/BaseContext'

export declare const rooms: RoomType[]

export declare function loadRooms(): Promise<void>

export type State = typeof state

type Actions = {
    loadRooms(context: Context): Promise<void>
}

type Mutations = {
    addRooms(state: State, rooms: RoomTypeResource[]): void
}

type Getters = {
    rooms(state: State): RoomType[]
}

type Context = BaseContext<State, Actions, Getters, Mutations>

const state: Record<string, RoomType> = {}

const actions: Actions = {
    async loadRooms({ getters, commit }): Promise<void> {
        if (getters.rooms.length > 0) {
            return
        }
        const roomTypes = await RoomService.getRooms()
        commit('addRooms', roomTypes)
    },
}

const mutations: Mutations = {
    addRooms(state: State, rooms: RoomTypeResource[]): void {
        rooms.forEach(r => {
            state[r.id] = <RoomType>{
                id: r.id,
                roomName: r.roomName,
                roomEnumId: r.roomEnumId,
                displayOrder: r.displayOrder,
                visible: r.visible,
            }
        })
    },
}

const getters: Getters = {
    rooms(state: State): RoomType[] {
        return Object.keys(state)
            .map(id => state[id])
            .sort((a, b) => {
                if (a.displayOrder > b.displayOrder) return 1
                if (a.displayOrder < b.displayOrder) return -1
                return 0
            })
    },
}

export default { state, actions, mutations, getters }
