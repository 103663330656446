import { LinkResource } from '@/models/api/resource/LinkResource'
import { MetaResource } from '@/models/api/resource/MetaResource'
import { ProjectResource } from '@/models/api/resource/ProjectResource'

export default class ProjectCollection {

    readonly data: ProjectResource[]
    readonly links: LinkResource
    readonly meta: MetaResource

    constructor(responseCollection: { data: ProjectResource[], links: LinkResource, meta: MetaResource }) {
        this.data = responseCollection.data
        this.links = responseCollection.links
        this.meta = responseCollection.meta
    }

    get length(): number {
        return this.data.length
    }

    toArray(): ProjectResource[] {
        return this.data
    }
}
