import api from '@/helpers/_api'
import { appConfig } from '@/helpers/_config'
import { ReferralOptionResource } from '@/models/api/resource/ReferralOptionResource'

export default class ReferralOptionService {

    static async loadReferralOptions(): Promise<ReferralOptionResource[]> {
        const username = appConfig.username
        const password = appConfig.password
        const response = (await api.get('/referral-options', { auth: { username, password } }))
        return response.data.data
    }
}
