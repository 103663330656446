import { isEmpty } from '@/helpers/_data'
import { User } from '@/models/User'
import AccountService from '@/services/AccountService'
import { BaseActions } from '@/store/BaseActions'

export declare const currentUser: User
export declare const isAuthenticated: boolean
export declare const isAdministrator: boolean
export declare const isCustomer: boolean
export declare const isDesigner: boolean

export declare function loadCurrentUser(): Promise<void>

export declare function setCurrentUser(user: User): void

type State = typeof state

type Actions = {
    loadCurrentUser: typeof loadCurrentUser
    setCurrentUser: typeof setCurrentUser
}

type Mutations = {
    setCurrentUser(state: State, user: User): void
}

type Getters = {
    currentUser(state: State): typeof currentUser
    isAuthenticated(state: State): typeof isAuthenticated
    isAdministrator(state: State): typeof isAdministrator
    isCustomer(user: State): typeof isCustomer
    isDesigner(user: State): typeof isDesigner
}

const state: { user: User } = { user: null }

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadCurrentUser({ commit }): Promise<void> {
        const user = await AccountService.getAccount()
        if (user) {
            commit('setCurrentUser', user)
        }
    },
    setCurrentUser({ commit }, user): void {
        commit('setCurrentUser', user)
    },
}

const mutations: Mutations = {
    setCurrentUser(state: State, user: User): void {
        state.user = {
            id: user.id,
            uuid: user.uuid,
            role: user.role,
            [user.role]: user[user.role],
            fullName: user.fullName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            timezone: user.timezone,
            lastFourDigits: user.lastFourDigits,
        }
    },
}

const getters: Getters = {
    currentUser(state) {
        return state.user
    },
    isAuthenticated(state) {
        return state.user !== null
            && !isEmpty(state.user.id)
            && !isEmpty(state.user.uuid)
            && !isEmpty(state.user.role)
            && !isEmpty(state.user.fullName)
            && !isEmpty(state.user.firstName)
            && !isEmpty(state.user.lastName)
            && !isEmpty(state.user.email)
    },
    isAdministrator(state) {
        return state.user?.role === 'administrator'
    },
    isCustomer(user) {
        return user.user?.role === 'customer'
    },
    isDesigner(user) {
        return user.user?.role === 'designer'
    },
}

export default { state, actions, getters, mutations }
