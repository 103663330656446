import { AttachmentType } from '@/enums/AttachmentType'
import { DeleteAttachmentRequest } from '@/models/api/request/DeleteAttachmentRequest'
import { UpdateAccountLocaleRequest } from '@/models/api/request/UpdateAccountLocaleRequest'
import { UpdateAccountPasswordRequest } from '@/models/api/request/UpdateAccountPasswordRequest'
import { UpdateAccountProfileRequest } from '@/models/api/request/UpdateAccountProfileRequest'
import { UpdateAccountRequest } from '@/models/api/request/UpdateAccountRequest'
import { AccountStatistics } from '@/models/api/resource/AccountStatistics'
import { CustomerProfileResource, DesignerProfileResource } from '@/models/api/resource/ProfileResource'
import { TransactionResource } from '@/models/api/resource/TransactionResource'
import { User } from '@/models/User'
import AccountService from '@/services/AccountService'
import { BaseActions } from '@/store/BaseActions'

export declare const accountProfile: CustomerProfileResource & DesignerProfileResource
export declare const accountStatistics: AccountStatistics
export declare const accountTransactions: TransactionResource[]

export declare function loadAccountProfile(): Promise<void>
export declare function loadAccountStatistics(): Promise<void>
export declare function loadAccountTransactions(): Promise<void>
export declare function updateAccount(request: UpdateAccountRequest): Promise<User>
export declare function updateAccountPassword(request: UpdateAccountPasswordRequest): Promise<void>
export declare function updateAccountProfile(request: UpdateAccountProfileRequest): Promise<void>
export declare function deleteAccountProfileAttachment(request: DeleteAttachmentRequest): Promise<void>

type State = {
    profile: typeof accountProfile
    statistics: typeof accountStatistics
    transactions: typeof accountTransactions
}

type Actions = {
    loadAccountProfile: typeof loadAccountProfile
    loadAccountStatistics: typeof loadAccountStatistics,
    loadAccountTransactions: typeof loadAccountTransactions
    updateAccount: typeof updateAccount
    updateAccountTimezone(payload: { timezone: string, force?: boolean }): Promise<void>
    updateAccountLocale(request: UpdateAccountLocaleRequest): Promise<void>
    updateAccountPassword: typeof updateAccountPassword
    updateAccountProfile: typeof updateAccountProfile
    deleteAccountProfileAttachment: typeof deleteAccountProfileAttachment
}

type Mutations = {
    SET_ACCOUNT_PROFILE(state: State, payload: { profile: typeof accountProfile })
    SET_ACCOUNT_STATISTICS(state: State, payload: { statistics: typeof accountStatistics }): void
    SET_ACCOUNT_TRANSACTIONS(state: State, payload: { transactions: typeof accountTransactions }): void
    REMOVE_ACCOUNT_PROFILE_ATTACHMENT(state: State, payload: { request: DeleteAttachmentRequest })
}

type Getters = {
    accountProfile(state: State): typeof accountProfile
    accountStatistics(state: State): typeof accountStatistics
    accountTransactions(state: State): typeof accountTransactions
}

const state: State = {
    profile: null,
    statistics: null,
    transactions: [],
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadAccountProfile({ commit }) {
        const profile = await AccountService.getAccountProfile()
        commit('SET_ACCOUNT_PROFILE', { profile })
    },
    async loadAccountStatistics({ commit }) {
        const statistics = await AccountService.getAccountStatistics()
        commit('SET_ACCOUNT_STATISTICS', { statistics })
    },
    async loadAccountTransactions({ commit }) {
        const transactions = await AccountService.getAccountTransactions()
        commit('SET_ACCOUNT_TRANSACTIONS', { transactions })
    },
    async updateAccount({ commit }, request) {
        const account = await AccountService.updateAccount(request)
        // @ts-ignore
        commit('setCurrentUser', account)
        return account
    },
    async updateAccountTimezone({ dispatch, rootGetters }, { timezone, force = false }): Promise<void> {
        if (force && rootGetters['currentUser'].timezone) {
            return
        }
        await AccountService.updateAccountLocale({ timezone })
        await dispatch('loadCurrentUser', { root: true })
    },
    async updateAccountLocale({ dispatch }, request) {
        await AccountService.updateAccountLocale(request)
        await dispatch('loadCurrentUser', { root: true })
    },
    async updateAccountPassword({}, request) {
        return AccountService.updateAccountPassword(request)
    },
    async updateAccountProfile({ commit }, request) {
        const profile = <CustomerProfileResource & DesignerProfileResource>await AccountService.updateAccountProfile(request)
        commit('SET_ACCOUNT_PROFILE', { profile })
    },
    async deleteAccountProfileAttachment({ commit }, request) {
        await AccountService.deleteAccountProfileAttachment(request)
        commit('REMOVE_ACCOUNT_PROFILE_ATTACHMENT', { request })
    },
}

const mutations: Mutations = {
    SET_ACCOUNT_PROFILE(state, { profile }) {
        state.profile = profile
    },
    SET_ACCOUNT_STATISTICS(state, { statistics }) {
        state.statistics = statistics
    },
    SET_ACCOUNT_TRANSACTIONS(state, { transactions }) {
        transactions.forEach(t => state.transactions.push(t))
    },
    REMOVE_ACCOUNT_PROFILE_ATTACHMENT(state, { request }) {
        let images = null
        if (request.type === AttachmentType.AVATAR) {
            images = state.profile.avatarImages
        }
        if (request.type === AttachmentType.COVER) {
            images = state.profile.coverImages
        }
        if (request.type === AttachmentType.SHOWCASE) {
            images = state.profile.showcaseImages
        }
        if (!images) {
            return
        }
        const index = images.findIndex(a => a.id === request.id)
        images.splice(index, 1)
    },
}

const getters: Getters = {
    accountProfile(state) {
        return state.profile
    },
    accountStatistics(state) {
        return state.statistics
    },
    accountTransactions(state) {
        return state.transactions
    },
}

export default { state, actions, getters, mutations }
