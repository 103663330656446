import api from '@/helpers/_api'
import { AppointmentConfigResource } from '@/models/api/resource/AppointmentConfigResource'
import { TimezoneConfigResource } from '@/models/api/resource/TimezoneConfigResource'

export default class ConfigurationService {

    static async getAppointmentConfig(): Promise<AppointmentConfigResource> {
        return (await api.get(`/configs/appointment`)).data.data
    }

    static async getTimezoneConfig(): Promise<TimezoneConfigResource> {
        return (await api.get('/configs/timezone')).data.data
    }
}
