import { UpdatePageSettingsRequest } from '@/models/api/request/UpdatePageSettingsRequest'
import { PageResource } from '@/models/api/resource/PageResource'
import PageService from '@/services/PageService'
import { BaseActions } from '@/store/BaseActions'

type State = {
    isWindowExpanded: boolean
    settings: PageResource
}

type Actions = {
    toggleWindow(): void
    loadSettings(): Promise<void>
    updateSettings(request: UpdatePageSettingsRequest): Promise<void>
}

type Mutations = {
    TOGGLE_WINDOW(state: State): void
    SET_SETTINGS(state: State, payload: { resource: PageResource }): void
}

type Getters = {
    isWindowExpanded(state: State): boolean
    settings(state: State): PageResource
}

const state: State = {
    isWindowExpanded: false,
    settings: null,
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    toggleWindow({ commit }) {
        commit('TOGGLE_WINDOW')
    },
    async loadSettings({ commit }) {
        const resource = await PageService.getPageSettings()
        commit('SET_SETTINGS', { resource })
    },
    async updateSettings({ commit }, request) {
        const resource = await PageService.updatePageSettings(request)
        commit('SET_SETTINGS', { resource })
    },
}

const mutations: Mutations = {
    TOGGLE_WINDOW(state) {
        state.isWindowExpanded = !state.isWindowExpanded
    },
    SET_SETTINGS(state, { resource }) {
        state.settings = resource
    },
}

const getters: Getters = {
    isWindowExpanded(state) {
        return state.isWindowExpanded
    },
    settings(state) {
        return state.settings
    },
}

export default { state, actions, mutations, getters }
