import api from '@/helpers/_api'
import { CreateAppointmentRequest } from '@/models/api/request/CreateAppointmentRequest'
import { CreateAvailabilityRequest } from '@/models/api/request/CreateAvailabilityRequest'
import { AppointmentResource } from '@/models/api/resource/AppointmentResource'
import { AvailabilityResource } from '@/models/api/resource/AvailabilityResource'
import { BriefResource } from '@/models/api/resource/BriefResource'
import { DateTime } from 'luxon'

export default class BriefService {

    static async getBrief(projectId: number): Promise<BriefResource> {
        const response = await api.get(`/projects/${projectId}/briefs`)
        return response.data.data
    }

    static async getAvailabilities(projectId: number): Promise<AvailabilityResource[]> {
        const response = await api.get(`/projects/${projectId}/briefs/availabilities`)
        return response.data.data.map(r => BriefService.transformResource(r, [ 'startTime', 'endTime' ]))
    }

    static async createAvailabilities(projectId: number, request: CreateAvailabilityRequest[]): Promise<AvailabilityResource[]> {
        const response = await api.post(`projects/${projectId}/briefs/availabilities/bulk`, request)
        return response.data.data.map(r => BriefService.transformResource(r, [ 'startTime', 'endTime' ]))
    }

    static async rejectAllAvailabilities(projectId: number): Promise<void> {
        await api.post(`/projects/${projectId}/briefs/availabilities/reject`, {})
    }

    static async bypassAppointment(projectId: number): Promise<void> {
        const response = await api.put(`/projects/${projectId}/briefs/flags`, { bypassAppointment: true })
        return response.data.data
    }

    static async getAppointment(projectId: number): Promise<AppointmentResource> {
        const response = await api.get(`/projects/${projectId}/briefs/appointment`)
        const resource = response.data.data
        resource.startTime = DateTime.fromISO(resource.startTime, { zone: 'UTC' })
        resource.endTime = DateTime.fromISO(resource.endTime, { zone: 'UTC' })
        return resource
    }

    static async createAppointment(projectId: number, request: CreateAppointmentRequest): Promise<AppointmentResource> {
        const response = await api.post(`projects/${projectId}/briefs/appointment`, request)
        return BriefService.transformResource(response.data.data, [ 'startTime', 'endTime' ])
    }

    static async deleteAppointment(projectId: number): Promise<void> {
        await api.delete(`/projects/${projectId}/briefs/appointment`)
    }

    static async createMeetingRoomAttendance(projectId: number): Promise<void> {
        await api.post(`/projects/${projectId}/briefs/appointment/meeting-room/attendance`)
    }

    private static transformResource(resource: any, keys: string[]): typeof resource {
        for (const key of keys) {
            resource[key] = DateTime.fromISO(resource[key], { zone: 'UTC' })
        }
        return resource
    }
}
