import { InviteResource } from '@/models/api/resource/InviteResource'
import InviteService from '@/services/InviteService'
import { BaseActions } from '@/store/BaseActions'

export declare const invites: InviteResource[]

export declare function loadInvites(): Promise<void>

type State = {
    invites: InviteResource[]
}

type Actions = {
    loadInvites: typeof loadInvites,
}

type Mutations = {
    APPEND_INVITE(state: State, payload: { resource: InviteResource })
}

type Getters = {
    invites(state: State): InviteResource[]
}

const state = {
    invites: [],
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadInvites({ state, commit }) {
        if (state.invites.length !== 0) {
            return
        }
        const resources = await InviteService.getInvites()
        resources.forEach(resource => commit('APPEND_INVITE', { resource }))
    },
}

const mutations: Mutations = {
    APPEND_INVITE(state, { resource }) {
        state.invites.push(resource)
    },
}

const getters: Getters = {
    invites(state) {
        return state.invites
    },
}

export default { state, actions, getters, mutations }
