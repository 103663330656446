import api, { options } from '@/helpers/_api'
import { apiConfig } from '@/helpers/_config'
import { CreatePasswordRequest } from '@/models/api/request/CreatePasswordRequest'
import { CreatePasswordResetTokenRequest } from '@/models/api/request/CreatePasswordResetTokenRequest'
import { DeleteAttachmentRequest } from '@/models/api/request/DeleteAttachmentRequest'
import { LoginRequest } from '@/models/api/request/LoginRequest'
import { PatchAccountProfileRequest } from '@/models/api/request/PatchAccountProfileRequest'
import { RegisterCustomerRequest } from '@/models/api/request/RegisterCustomerRequest'
import { RegisterDesignerRequest } from '@/models/api/request/RegisterDesignerRequest'
import { UpdateAccountLocaleRequest } from '@/models/api/request/UpdateAccountLocaleRequest'
import { UpdateAccountPasswordRequest } from '@/models/api/request/UpdateAccountPasswordRequest'
import { UpdateAccountProfileRequest } from '@/models/api/request/UpdateAccountProfileRequest'
import { UpdateAccountRequest } from '@/models/api/request/UpdateAccountRequest'
import { AccountStatistics } from '@/models/api/resource/AccountStatistics'
import { CustomerProfileResource, DesignerProfileResource, ProfileResource } from '@/models/api/resource/ProfileResource'
import { TransactionResource } from '@/models/api/resource/TransactionResource'
import { PaymentCard } from '@/models/PaymentCard'
import { User } from '@/models/User'
import axios from 'axios'

export default class AccountService {

    static async loginAccount({ email, password, rememberMe }: LoginRequest): Promise<any> {
        const client   = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        const response = await client.post('auth/login', { email, password, remember: rememberMe })
        return response.data
    }

    static async createPasswordResetToken(request: CreatePasswordResetTokenRequest): Promise<void> {
        const client = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        await client.post('/password/email', request)
    }

    static async createPassword(request: CreatePasswordRequest): Promise<void> {
        const client = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        await client.post('/password/reset', {
            token: request.token,
            email: request.email,
            password: request.password,
            password_confirmation: request.confirmPassword,
        })
    }

    static async registerCustomerAccount(request: RegisterCustomerRequest): Promise<any> {
        const client   = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        const response = await client.post('auth/register', {
            email: request.email,
            password: request.password,
            first_name: request.firstName,
            user_type: 'App\\Customer',
            is_customer: true,
        })
        return response.data
    }

    static async registerDesignerAccount(request: RegisterDesignerRequest): Promise<any> {
        const client   = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        const response = await client.post('auth/register', {
            email: request.email,
            password: request.password,
            first_name: request.firstName,
            last_name: request.lastName,
            post_code: request.postcode,
            referred_by: request.referredBy,
            referred_by_text: request.referredByOther,
            user_type: 'App\\Designer',
            is_designer: true,
        })
        return response.data
    }

    static async getAccount(): Promise<User> {
        try {
            const client   = axios.create(options)
            const response = await client.get('account')
            return <User>response.data.data
        }
        catch (err: any) {
            return null
        }
    }

    static async updateAccount(request: UpdateAccountRequest): Promise<User> {
        const response = await api.put('account', request)
        return <User>response.data.data
    }

    static async updateAccountLocale(request: UpdateAccountLocaleRequest): Promise<void> {
        await api.put('account/locale', request)
    }

    static async updateAccountPassword(request: UpdateAccountPasswordRequest): Promise<void> {
        return await api.put('account/password', request)
    }

    static async getAccountProfile(): Promise<ProfileResource & CustomerProfileResource & DesignerProfileResource> {
        const response = await api.get('account/profile')
        return response.data.data
    }

    static async patchAccountProfile(request: PatchAccountProfileRequest): Promise<CustomerProfileResource | DesignerProfileResource> {
        const response = await api.patch('account/profile', request)
        return <CustomerProfileResource | DesignerProfileResource>response.data.data
    }

    static async updateAccountProfile(request: UpdateAccountProfileRequest): Promise<CustomerProfileResource | DesignerProfileResource> {
        const response = await api.put('account/profile', request)
        return <CustomerProfileResource | DesignerProfileResource>response.data.data
    }

    static async deleteAccountProfileAttachment(request: DeleteAttachmentRequest): Promise<void> {
        await api.delete(`account/profile/attachments/${request.id}`, { data: {} })
    }

    static async getAccountStatistics(): Promise<AccountStatistics> {
        const response = await api.get('account/statistics')
        return <AccountStatistics>response.data.data
    }

    static async getAccountTransactions(): Promise<TransactionResource[]> {
        const response = await api.get('account/transactions')
        return <TransactionResource[]>response.data.data.map(function (transaction: TransactionResource) {
            transaction.createdAt = new Date(transaction.createdAt)
            transaction.updatedAt = new Date(transaction.updatedAt)
            return transaction
        })
    }

    static async updateAccountCard(card: PaymentCard): Promise<void> {
        await api.put(
            `account/card`,
            {
                name: card.name,
                number: card.number,
                expiryMonth: card.expiryMonth,
                expiryYear: card.expiryYear.toString().substring(2, 4),
                cvc: card.cvc,
            })
    }
}
