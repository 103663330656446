import api from '@/helpers/_api'
import ProjectCollection from '@/models/api/collections/ProjectCollection'
import Filter from '@/models/api/filters/Filter'
import { CloseProjectRequest } from '@/models/api/request/CloseProjectRequest'
import { CreateShoppingItemRequest } from '@/models/api/request/CreateShoppingItemRequest'
import { PackageRequest } from '@/models/api/request/PackageRequest'
import { PatchShoppingItemRequest } from '@/models/api/request/PatchShoppingItemRequest'
import { ProjectFeedbackRequest } from '@/models/api/request/ProjectFeedbackRequest'
import { PublishPackageRequest } from '@/models/api/request/PublishPackageRequest'
import { UpdateShoppingItemRequest } from '@/models/api/request/UpdateShoppingItemRequest'
import { ActivityResource } from '@/models/api/resource/ActivityResource'
import { FirstLookResource } from '@/models/api/resource/FirstLookResource'
import { ProjectFeedbackResource } from '@/models/api/resource/ProjectFeedbackResource'
import { PackageResource } from '@/models/api/resource/ProjectPackageResource'
import { ProjectResource } from '@/models/api/resource/ProjectResource'
import { ShoppingItemResource } from '@/models/api/resource/ShoppingItemResource'
import FormService from '@/services/FormService'
import store from '@/store'

export default class ProjectService {

    static async getProjects(filter?: Filter): Promise<ProjectCollection> {
        const url = 'projects'.concat(filter.toQueryString())
        const response = await api.get(url)
        response.data.data = <ProjectResource[]>response.data.data.map(ProjectService.sanitizeProjectResponse)
        return new ProjectCollection(response.data)
    }

    static async getProject(projectId): Promise<ProjectResource> {
        const response = await api.get(`projects/${projectId}`)
        const project = <ProjectResource>response.data.data
        return ProjectService.sanitizeProjectResponse(project)
    }

    static async closeProject(projectId: number, request: CloseProjectRequest): Promise<ProjectResource> {
        const response = await api.post(`projects/${projectId}/close`, request)
        const project  = <ProjectResource>response.data.data
        return ProjectService.sanitizeProjectResponse(project)
    }

    static async flagProjectAsShortlistRequested(projectId: number): Promise<ProjectResource> {
        const response = await api.put(`projects/${projectId}/flags`, { shortlistRequested: true })
        const project  = <ProjectResource>response.data.data
        return ProjectService.sanitizeProjectResponse(project)
    }

    static async getPackage(projectId): Promise<PackageResource> {
        return <PackageResource>(await api.get(`projects/${projectId}/package`)).data.data
    }

    static async updatePackage(projectId: number, packageRequest: PackageRequest): Promise<PackageResource> {
        return <PackageResource>(await api.put(`projects/${projectId}/package`, packageRequest)).data.data
    }

    static async publishPackage(projectId: number, data: PublishPackageRequest): Promise<PackageResource> {
        return <PackageResource>(await api.post(`projects/${projectId}/package/publish`, data)).data.data
    }

    static async flagPackageAsViewed(projectId: number): Promise<PackageResource> {
        return <PackageResource>(await api.put(`projects/${projectId}/package/flags`, { viewed: true })).data.data
    }

    static async createShoppingItem(projectId: number, shoppingItemRequest: CreateShoppingItemRequest): Promise<ShoppingItemResource> {
        return <ShoppingItemResource>(await api.post(
            `projects/${projectId}/package/shopping-items`,
            FormService.convertJsonToFormData(shoppingItemRequest),
            { headers: { 'Content-Type': 'multipart/form-data' } })).data.data
    }

    static async updateShoppingItem(projectId: number, shoppingItemId: number, shoppingItemRequest: UpdateShoppingItemRequest): Promise<ShoppingItemResource> {
        return <ShoppingItemResource>(await api.post(
            `projects/${projectId}/package/shopping-items/${shoppingItemId}`,
            FormService.convertJsonToFormData(shoppingItemRequest),
            { headers: { 'Content-Type': 'multipart/form-data' } })).data.data
    }

    static async patchShoppingItem(projectId: number, shoppingItemId: number, shoppingItemRequest: PatchShoppingItemRequest): Promise<ShoppingItemResource> {
        return <ShoppingItemResource>(await api.patch(`projects/${projectId}/package/shopping-items/${shoppingItemId}`, shoppingItemRequest)).data.data
    }

    static async deleteShoppingItem(projectId: number, shoppingItemId: number): Promise<void> {
        await api.delete(`projects/${projectId}/package/shopping-items/${shoppingItemId}`, { data: {} })
    }

    static async deletePackageFloorPlanById(projectId, floorPlanId): Promise<void> {
        await api.delete(`projects/${projectId}/package/floor-plans/${floorPlanId}`, { data: {} })
    }

    static async deletePackageDesignBoardById(projectId, designBoardId): Promise<void> {
        await api.delete(`projects/${projectId}/package/design-boards/${designBoardId}`, { data: {} })
    }

    static async createProjectFeedback(projectId: number, projectFeedbackRequest: ProjectFeedbackRequest): Promise<ProjectFeedbackResource> {
        return <ProjectFeedbackResource>(await api.post(`projects/${projectId}/feedback`, projectFeedbackRequest)).data.data
    }

    private static sanitizeProjectResponse(project: ProjectResource): ProjectResource {
        project.createdAt  = new Date(project.createdAt)
        project.updatedAt  = new Date(project.updatedAt)
        project.upgradedAt = project.upgradedAt ? new Date(project.upgradedAt) : null
        project.closedAt   = project.closedAt ? new Date(project.closedAt) : null
        project.activities.map(function (activity: ActivityResource) {
            activity.createdAt = new Date(activity.createdAt)
            activity.updatedAt = new Date(activity.updatedAt)
            return activity
        })
        project.firstLooks.map(function (firstLook: FirstLookResource) {
            firstLook.createdAt = new Date(firstLook.createdAt)
            firstLook.updatedAt = new Date(firstLook.updatedAt)
        })
        return project
    }

    static async validateProjectIsAccessible(projectId: number): Promise<void> {
        const project: ProjectResource = <ProjectResource>await store.dispatch('getProject', projectId)
        if (project.closedAt) {
            throw new Error('Project is closed')
        }
    }

    static async validatePackageIsAccessible(projectId: number): Promise<void> {
        const project: ProjectResource = <ProjectResource>await store.dispatch('getProject', projectId)
        if (project.closedAt && !project.package.publishedAt) {
            throw new Error('Project is closed')
        }
    }
}
