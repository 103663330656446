import { StyleResource } from '@/models/api/resource/StyleResource'
import StyleService from '@/services/StyleService'
import { BaseActions } from '@/store/BaseActions'

export declare const styles: StyleResource[]

export declare function loadStyles(): Promise<void>

type State = {
    styles: Record<string, StyleResource>
}

type Actions = {
    loadStyles: typeof loadStyles
}

type Mutations = {
    ADD_STYLES(state: State, payload: { resources: StyleResource[] })
}

type Getters = {
    styles(state: State): typeof styles
    style(state: State): (id) => StyleResource
}

const state: State = {
    styles: {}
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadStyles({ getters, commit }) {
        if (getters.styles.length > 0) {
            return
        }
        const resources = await StyleService.getStyles()
        commit('ADD_STYLES', { resources })
    },
}

const mutations: Mutations = {
    ADD_STYLES(state, { resources }) {
        resources.forEach(r => {
            state.styles[r.id] = {
                id: r.id,
                name: r.name,
                description: r.description,
            }
        })
    },
}

const getters: Getters = {
    styles(state) {
        return Object.keys(state.styles).map(id => state.styles[id])
    },
    style(state) {
        return (id) => state.styles[id]
    }
}

export default { state, actions, getters, mutations }
