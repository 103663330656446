import app from '@/store/modules'
import account from '@/store/modules/account'
import brief from '@/store/modules/brief'
import chat from '@/store/modules/chat'
import config from '@/store/modules/config'
import designer from '@/store/modules/designer'
import firstLook from '@/store/modules/first-look'
import invite from '@/store/modules/invite'
import page from '@/store/modules/page'
import product from '@/store/modules/product'
import referralOption from '@/store/modules/referral-option'
import styleQuiz from '@/store/modules/style-quiz'
import welcome from '@/store/modules/welcome'
import { createStore } from 'vuex'
import auth from './modules/auth'
import cart from './modules/cart'
import plan from './modules/plan'
import project from './modules/project'
import room from './modules/room'
import style from './modules/style'

export default createStore({
    strict: process.env.NODE_ENV !== 'production',
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app, account, auth, cart, designer, firstLook, invite, plan, product, project, referralOption, room, style, styleQuiz,
        brief: { namespaced: true, ...brief },
        chat: { namespaced: true, ...chat },
        config: { namespaced: true, ...config },
        page: { namespaced: true, ...page },
        welcome: { namespaced: true, ...welcome },
    },
})
