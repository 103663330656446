import api from '@/helpers/_api'
import { ActivityResource } from '@/models/api/resource/ActivityResource'
import { DesignerFirstLookResource } from '@/models/api/resource/DesignerFirstLookResource'

export default class FirstLookService {

    static async getFirstLooks(): Promise<DesignerFirstLookResource[]> {
        const response = await api.get('first-looks')
        const invites  = <DesignerFirstLookResource[]>response.data.data
        return invites.map(FirstLookService.sanitizeFirstLookResponse)
    }

    private static sanitizeFirstLookResponse(firstLook: DesignerFirstLookResource): DesignerFirstLookResource {
        firstLook.createdAt          = new Date(firstLook.createdAt)
        firstLook.updatedAt          = new Date(firstLook.updatedAt)
        firstLook.publishedAt        = new Date(firstLook.publishedAt)
        firstLook.project.createdAt  = new Date(firstLook.project.createdAt)
        firstLook.project.updatedAt  = new Date(firstLook.project.updatedAt)
        firstLook.project.upgradedAt = firstLook.project.upgradedAt ? new Date(firstLook.project.upgradedAt) : null
        firstLook.project.closedAt   = firstLook.project.closedAt ? new Date(firstLook.project.closedAt) : null
        firstLook.project.activities.map(function (activity: ActivityResource) {
            activity.createdAt = new Date(activity.createdAt)
            activity.updatedAt = new Date(activity.updatedAt)
            return activity
        })
        return firstLook
    }
}
