import Filter, { FilterOperator } from '@/models/api/filters/Filter'
import { DesignerResource } from '@/models/api/resource/DesignerResource'
import DesignerService from '@/services/DesignerService'
import { BaseActions } from '@/store/BaseActions'
import { DesignerFilterOptions } from '@/types/DesignerFilterOptions'
import orderBy from 'lodash/orderBy'
import { useRoute } from 'vue-router'

export declare const designer: DesignerResource
export declare const designers: DesignerResource[]
export declare const designerFilterOptions: DesignerFilterOptions

export declare function loadDesigners(): Promise<void>

export declare function loadDesigner(designerSlug?: string): Promise<void>

function getDesignerSlug(): string | null {
    return <string>useRoute().params['designerSlug'] ?? null
}

type Actions = {
    loadDesigners: typeof loadDesigners
    loadDesigner: typeof loadDesigner
}

type Getters = {
    designer(state: State): typeof designer
    designers(state: State): typeof designers
    designerFilterOptions(state: State): typeof designerFilterOptions
}

type Mutations = {
    APPEND_DESIGNERS(state: State, payload: { designers: DesignerResource[] }): void
    SET_ACTIVE_DESIGNER(state: State, payload: { designer: DesignerResource }): void
}

type State = {
    designer: DesignerResource,
    designers: Map<string, DesignerResource>
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadDesigners({ state, commit }): Promise<void> {
        if (state.designers.size !== 0) {
            return
        }
        let nextPage = 1
        do {
            const filter     = (new Filter())
                .add('page', FilterOperator.SIZE, 12)
                .add('page', FilterOperator.NUMBER, nextPage)
            const collection = await DesignerService.getDesigners(filter)
            const designers  = collection.toArray()
            nextPage         = collection.links.next !== null ? ++nextPage : null
            commit('APPEND_DESIGNERS', { designers })
        }
        while (nextPage)
    },
    async loadDesigner({ getters, commit }, designerSlug = getDesignerSlug()): Promise<void> {
        const designer = state.designers.get(designerSlug) ?? await DesignerService.getDesigner(designerSlug)
        commit('SET_ACTIVE_DESIGNER', { designer })
    },
}

const getters: Getters = {
    designer(state) {
        return state.designer
    },
    designers(state) {
        const designers = Array.from(state.designers.values())
        return orderBy(designers, [ 'isAvailable', 'rating' ], [ 'desc', 'desc' ])
    },
    designerFilterOptions(state): DesignerFilterOptions {
        const availabilities = new Set()
        const locations      = new Set()
        const plans          = new Set()
        const ratings        = new Set()
        const rooms          = new Set()
        const styles         = new Set()
        for (const designer of state.designers.values()) {
            availabilities.add(designer.isAvailable)
            locations.add(designer.address.city)
            plans.add(designer.plan.slug)
            ratings.add(designer.rating)
            designer.styles.forEach(style => styles.add(style))
        }
        return {
            availabilities: <boolean[]>Array.from(availabilities.values()).sort().reverse(),
            locations: <string[]>Array.from(locations.values()).sort(),
            plans: <string[]>Array.from(plans.values()).sort(),
            ratings: <number[]>Array.from(ratings.values()).sort().reverse(),
            rooms: <string[]>Array.from(rooms.values()).sort(),
            styles: <string[]>Array.from(styles.values()).sort(),
        }
    },
}

const mutations: Mutations = {
    APPEND_DESIGNERS(state, { designers }) {
        for (const designer of designers) {
            state.designers.set(designer.slug, designer)
        }
    },
    SET_ACTIVE_DESIGNER(state, { designer }) {
        state.designer = designer
    },
}

const state: State = {
    designer: null,
    designers: new Map(),
}

export default { state, actions, getters, mutations }
