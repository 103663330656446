import { AppointmentConfigResource } from '@/models/api/resource/AppointmentConfigResource'
import { TimezoneConfigResource } from '@/models/api/resource/TimezoneConfigResource'
import ConfigurationService from '@/services/ConfigurationService'
import { BaseActions } from '@/store/BaseActions'

type State = {
    appointment: AppointmentConfigResource
    timezone: TimezoneConfigResource
}

type Actions = {
    loadAppointmentConfig(): Promise<void>
    loadTimezoneConfig(): Promise<void>
}

type Mutations = {
    UPDATE_APPOINTMENT_CONFIG(state: State, payload: { resource: AppointmentConfigResource }): void
    UPDATE_TIMEZONE_CONFIG(state: State, payload: { resource: TimezoneConfigResource }): void
}

type Getters = {
    isAppointmentConfigLoaded(state: State): boolean
    isTimezoneConfigLoaded(state: State): boolean
    appointmentDays(state: State): number[]
    appointmentTimezone(state: State): string
    appointmentOpeningTime(state: State): string
    appointmentClosingTime(state: State): string
    appointmentDurationMins(state: State): number
    appointmentGracePeriod(state: State): number
    appointmentAvailabilityPeriod(state: State): number
    serverTimezone(state: State): string
    userTimezones(state: State): string[]
}

const state: State = {
    appointment: null,
    timezone: null,
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadAppointmentConfig({ state, commit }) {
        if (state.appointment) {
            return
        }
        const resource = await ConfigurationService.getAppointmentConfig()
        commit('UPDATE_APPOINTMENT_CONFIG', { resource })
    },
    async loadTimezoneConfig({ state, commit }) {
        if (state.timezone) {
            return
        }
        const resource = await ConfigurationService.getTimezoneConfig()
        commit('UPDATE_TIMEZONE_CONFIG', { resource })
    },
}

const mutations: Mutations = {
    UPDATE_APPOINTMENT_CONFIG(state, { resource }) {
        state.appointment = resource
    },
    UPDATE_TIMEZONE_CONFIG(state, { resource }) {
        state.timezone = resource
    },
}

const getters: Getters = {
    isAppointmentConfigLoaded(state) {
        return !!state.appointment
    },
    isTimezoneConfigLoaded(state) {
        return !!state.timezone
    },
    appointmentDays(state) {
        return state.appointment.days
    },
    appointmentTimezone(state) {
        return state.appointment.timezone
    },
    appointmentOpeningTime(state) {
        return state.appointment.openingTime
    },
    appointmentClosingTime(state) {
        return state.appointment.closingTime
    },
    appointmentDurationMins(state) {
        return state.appointment.durationMins
    },
    appointmentGracePeriod(state) {
        return state.appointment.gracePeriod
    },
    appointmentAvailabilityPeriod(state) {
        return state.appointment.availabilityPeriod
    },
    serverTimezone(state) {
        return state.timezone?.serverTimezone ?? null
    },
    userTimezones(state) {
        return state.timezone?.userTimezones ?? []
    },
}

export default { state, actions, mutations, getters }
