import { DesignerFirstLookResource } from '@/models/api/resource/DesignerFirstLookResource'
import FirstLookService from '@/services/FirstLookService'
import { BaseActions } from '@/store/BaseActions'

export declare const firstLooks: DesignerFirstLookResource[]

export declare function loadFirstLooks(): Promise<void>

type State = {
    firstLooks: typeof firstLooks
}

type Actions = {
    loadFirstLooks: typeof loadFirstLooks
}

type Mutations = {
    APPEND_FIRST_LOOK(state: State, payload: { resource: DesignerFirstLookResource }): void
}

type Getters = {
    firstLooks(state: State): typeof firstLooks
}

const state = {
    firstLooks: [],
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadFirstLooks({ state, commit }) {
        if (state.firstLooks.length !== 0) {
            return
        }
        const resources = await FirstLookService.getFirstLooks()
        resources.forEach(resource => commit('APPEND_FIRST_LOOK', { resource }))
    },
}

const mutations: Mutations = {
    APPEND_FIRST_LOOK(state, { resource }) {
        state.firstLooks.push(resource)
    },
}

const getters: Getters = {
    firstLooks(state) {
        return state.firstLooks
    },
}

export default { state, actions, getters, mutations }
