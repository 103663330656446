import api from '@/helpers/_api'
import { appConfig } from '@/helpers/_config'
import DesignerCollection from '@/models/api/collections/DesignerCollection'
import Filter from '@/models/api/filters/Filter'
import { DesignerResource } from '@/models/api/resource/DesignerResource'

export default class DesignerService {

    static async getDesigners(filter: Filter): Promise<DesignerCollection> {
        const username = appConfig.username
        const password = appConfig.password
        const url      = '/designers'.concat(filter.toQueryString())
        const response = await api.get(url, { auth: { username, password } })
        return new DesignerCollection(response.data)
    }

    static async getDesigner(designerSlug: string): Promise<DesignerResource> {
        const username = appConfig.username
        const password = appConfig.password
        const url      = '/designers/'.concat(designerSlug)
        const response = await api.get(url, { auth: { username, password } })
        return <DesignerResource>response.data.data
    }
}
