import { CartResource } from '@/models/api/resource/CartResource'
import { Cart } from '@/models/Cart'
import { CartItem } from '@/models/CartItem'
import CartService from '@/services/CartService'
import { BaseContext } from '@/store/BaseContext'
import Decimal from 'decimal.js'

type State = typeof state

type Actions = {
    calculateCart(context: Context, overrides?: Partial<Cart>): Promise<CartResource>
    addCartItem(context: Context, cartItem: CartItem): Promise<void>
    removeCartItem(context: Context, cartItem: CartItem): Promise<void>
    applyCartCoupon(context: Context, couponCode: string): Promise<void>
    applyCartGift(context: Context, giftCode: string): Promise<void>
    updateCartPlan(context: Context, planId: number): Promise<void>
    resetCart(context: Context): void
}

type Mutations = {
    applyCartCalculations(state: State, data: CartResource): void
    seedCartItem(state: State): void
    addCartItem(state: State, item: CartItem): void
    removeCartItem(state: State, item: CartItem): void
    updateCartPlan(state: State, planId: number): void
    RESET_CART(state: State): void
}

type Getters = {
    cart(state: Cart): Cart
    cartPlanId(state: Cart): number
    cartItems(state: Cart): CartItem[]
    calculatedCartItems(state: Cart): CartItem[]
    cartCouponCode(state: Cart): string
    cartCouponTotal(state: Cart): number
    cartGiftCode(state: Cart): string
    cartGiftTotal(state: Cart): number
    cartTaxTotal(state: Cart): number
    cartTotal(state: Cart): number
}

type Context = BaseContext<State, Actions, Getters, Mutations>

const defaultState = (): Cart => ({
    items: [ new CartItem() ],
    planId: null,
    couponCode: null,
    couponTotal: 0,
    giftCode: null,
    giftTotal: 0,
    itemTotal: 0,
    total: 0,
})

const state: Cart = defaultState()

const actions: Actions = {
    async calculateCart({ commit, state }: Context, overrides?: Partial<Cart>): Promise<CartResource> {
        if (!state.planId) {
            return
        }
        const cart = await CartService.calculateCart(Object.assign({}, state, overrides))
        commit('applyCartCalculations', cart)
        return cart
    },
    async addCartItem({ commit, dispatch }: Context, cartItem: CartItem): Promise<void> {
        commit('addCartItem', cartItem)
        commit('seedCartItem')
        await dispatch('calculateCart')
    },
    async removeCartItem({ commit, dispatch }: Context, cartItem: CartItem): Promise<void> {
        commit('removeCartItem', cartItem)
        await dispatch('calculateCart')
    },
    async applyCartCoupon({ dispatch }: Context, couponCode: string): Promise<void> {
        const cart = await dispatch('calculateCart', { couponCode })
        if (couponCode !== null && couponCode !== '' && cart.couponCode === null) {
            throw new Error('The promocode requirements were not met, please check your cart and try again')
        }
    },
    async applyCartGift({ dispatch }: Context, giftCode: string): Promise<void> {
        await dispatch('calculateCart', { giftCode })
    },
    async updateCartPlan({ commit, dispatch, getters }: Context, planId: number): Promise<void> {
        commit('updateCartPlan', planId)
        if (getters.calculatedCartItems.length > 0) {
            await dispatch('calculateCart')
        }
    },
    resetCart({ commit }) {
        commit('RESET_CART')
    },
}

const mutations: Mutations = {
    applyCartCalculations(state: State, data: CartResource): void {
        data.items.map(resItem => {
            const cartItem       = state.items.find(i => i.refId === resItem.refId)
            cartItem.planTotal   = resItem.planTotal
            cartItem.couponTotal = resItem.couponTotal
            cartItem.total       = resItem.total
        })
        state.couponCode  = data.couponCode
        state.couponTotal = data.couponTotal
        state.giftCode    = data.giftCode
        state.giftTotal   = data.giftTotal
        state.itemTotal   = data.itemTotal
        state.total       = data.total
    },
    seedCartItem(state: State): void {
        const lastProject = state.items[state.items.length - 1]
        if (lastProject.isCommitted) {
            state.items.push(new CartItem())
        }
    },
    addCartItem(state: State, item: CartItem): void {
        const idx        = state.items.findIndex(i => i.refId == item.refId)
        item.planId      = state.planId
        item.planTotal   = 0
        item.couponTotal = 0
        item.total       = 0
        state.items[idx] = item
    },
    removeCartItem(state: State, item: CartItem): void {
        const idx = state.items.findIndex(p => p.refId == item.refId)
        state.items.splice(idx, 1)
    },
    updateCartPlan(state: State, planId: number): void {
        state.planId = planId
        state.items  = state.items.map(i => {
            i.planId = planId
            return i
        })
    },
    RESET_CART(state) {
        Object.assign(state, defaultState())
    }
}

const getters: Getters = {
    cart(state: State): Cart {
        return state
    },
    cartPlanId(state: State): number {
        return state.planId
    },
    cartItems(state: State): CartItem[] {
        return state.items
    },
    calculatedCartItems(state: State): CartItem[] {
        return state.items.filter(i => i.isCommitted)
    },
    cartCouponCode(state: State): string {
        return state.couponCode
    },
    cartCouponTotal(state: State): number {
        return state.couponTotal
    },
    cartGiftCode(state: State): string {
        return state.giftCode
    },
    cartGiftTotal(state: State): number {
        return state.giftTotal
    },
    cartTaxTotal(state: State): number {
        return new Decimal(state.total).dividedBy(11).toNumber()
    },
    cartTotal(state: State): number {
        return state.total
    },
}

export default { state, actions, mutations, getters }
