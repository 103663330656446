export enum FilterOperator {
    IN     = 'in',
    NUMBER = 'number',
    SIZE   = 'size',
    VALUE  = 'value',
}

export default class Filter {

    private filters = []

    add(key: string, operator: FilterOperator, value: boolean | string | number) {
        this.filters.push([ key, operator, value ])
        return this
    }

    toQueryString(): string {
        if (this.filters.length === 0) {
            return ''
        }
        let queryString = this.filters.reduce((acc, [ key, operator, value ]) => {
            acc += `&${key}[${operator}]=${value}`
            return acc
        }, '')
        return queryString.replace(queryString.charAt(0), '?')
    }
}
