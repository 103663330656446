export const apiConfig = {
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL || 'http://localhost:8080/api',
    baseUrl: process.env.VUE_APP_BASE_URL || 'http://localhost:8080',
}

export const appConfig = {
    contextPath: process.env.VUE_APP_CONTEXT_PATH ?? process.env.APP_CONTEXT_PATH ?? '',
    gaCode: process.env.VUE_APP_GA_CODE ?? process.env.GA_CODE,
    gtmCode: process.env.VUE_APP_GTM_CODE ?? process.env.GTM_ID,
    intercomCode: process.env.VUE_APP_INTERCOM_CODE ?? process.env.INTERCOM_APP_ID,
    password: process.env.VUE_APP_PASSWORD,
    username: process.env.VUE_APP_USERNAME,
    isMixApp: process.env.MIX_APP === 'true',
}

export const broadcastConfig = {
    authEndpoint: process.env.VUE_APP_BROADCAST_AUTH_ENDPOINT ?? '/broadcasting/auth',
    cluster: process.env.VUE_APP_BROADCAST_CLUSTER,
    key: process.env.VUE_APP_BROADCAST_KEY,
    service: process.env.VUE_APP_BROADCAST_SERVICE ?? 'pusher',
}
