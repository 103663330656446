import { options } from '@/helpers/_api'
import { apiConfig, appConfig } from '@/helpers/_config'
import { StyleResource } from '@/models/api/resource/StyleResource'
import axios from 'axios'

export default class StyleService {

    static async getStyles(): Promise<StyleResource[]> {
        const client   = axios.create(Object.assign({}, options, { baseURL: apiConfig.baseUrl }))
        const username = appConfig.username
        const password = appConfig.password
        const response = await client.get('api/styles', { auth: { username, password } })
        return <StyleResource[]>response.data.data
    }
}
