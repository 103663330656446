
import { FooterMode } from '@/enums/FooterMode'
import { HeaderMode } from '@/enums/HeaderMode'
import { appConfig } from '@/helpers/_config'
import { User } from '@/models/User'
import { isGlobalError, isLoading } from '@/store/modules'
import { currentUser } from '@/store/modules/auth'
import { defineAsyncComponent, watch } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

@Options({
    components: {
        AppError: defineAsyncComponent(() => import('./components/AppError.vue')),
        AppHeader: defineAsyncComponent(() => import('./components/AppHeader.vue')),
        AppFooter: defineAsyncComponent(() => import('./components/AppFooter.vue')),
        ClosureBanner: defineAsyncComponent(() => import('./components/ClosureBanner.vue')),
        Loading: defineAsyncComponent(() => import('vue-loading-overlay')),
        PageSettings: defineAsyncComponent(() => import('./components/PageSettings.vue')),
    },
    computed: {
        ...mapGetters([
            'isGlobalError',
            'isLoading',
            'currentUser',
        ]),
    },
})
export default class App extends Vue {

    isGlobalError: typeof isGlobalError
    isLoading: typeof isLoading
    currentUser: typeof currentUser
    user: typeof currentUser

    get appCustomClass(): string {
        return <string>this.$route?.meta?.appCustomClass ?? null
    }

    get headerMode(): HeaderMode {
        return <HeaderMode>this.$route.meta?.headerMode
    }

    get headerModeOff(): boolean {
        return this.headerMode === HeaderMode.OFF
    }

    get footerMode(): FooterMode {
        return <FooterMode>this.$route.meta?.footerMode
    }

    get footerModeOff(): boolean {
        return this.footerMode === FooterMode.OFF
    }

    $intercom: {
        boot: (opts: { user_id: number, email: string, name: string, phone: string }) => void,
        show: () => void
    }

    async created(): Promise<void> {
        const stopWatchingUser = watch(
            () => this.currentUser,
            (user) => {
                stopWatchingUser()
                this.initIntercom(user)
            })
        if (this.$router) {
            this.$watch(
                () => this.$route?.path,
                () => document.getElementById('app').className = this.appCustomClass ?? '')
        }
    }

    private initIntercom(user: User): void {
        if (appConfig.intercomCode && window['Intercom']) {
            this.$intercom.boot({
                user_id: user.id,
                email: user.email,
                name: user.fullName,
                phone: user.phoneNumber,
            })
        }
    }
}
