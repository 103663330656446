export class CartItem {
    refId: string
    planId?: number
    roomId?: number
    planTotal?: number
    couponTotal?: number
    total?: number

    constructor(args?: any) {
        this.refId = args?.refId ?? Math.random().toString(36).substring(7)
        this.roomId = args?.roomId ?? null
        this.planTotal = args?.planTotal ?? null
        this.couponTotal = args?.couponTotal ?? null
        this.total = args?.total ?? null
    }

    get isCommitted(): boolean {
        return this.planTotal !== null
    }
}
