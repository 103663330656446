import api from '@/helpers/_api'
import { ActivityResource } from '@/models/api/resource/ActivityResource'
import { InviteResource } from '@/models/api/resource/InviteResource'

export default class InviteService {

    static async getInvites(): Promise<InviteResource[]> {
        const response = await api.get('invites')
        const invites = <InviteResource[]>response.data.data
        return invites.map(InviteService.sanitizeInviteResponse)
    }

    private static sanitizeInviteResponse(invite: InviteResource): InviteResource {
        invite.createdAt = new Date(invite.createdAt)
        invite.updatedAt = new Date(invite.updatedAt)
        invite.project.createdAt  = new Date(invite.project.createdAt)
        invite.project.updatedAt  = new Date(invite.project.updatedAt)
        invite.project.upgradedAt = invite.project.upgradedAt ? new Date(invite.project.upgradedAt) : null
        invite.project.closedAt   = invite.project.closedAt ? new Date(invite.project.closedAt) : null
        invite.project.activities.map(function (activity: ActivityResource) {
            activity.createdAt = new Date(activity.createdAt)
            activity.updatedAt = new Date(activity.updatedAt)
            return activity
        })
        return invite
    }
}
