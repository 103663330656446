import { options } from '@/helpers/_api'
import { appConfig } from '@/helpers/_config'
import { PlanResource } from '@/models/api/resource/PlanResource'
import { Plan } from '@/models/Plan'
import { BaseContext } from '@/store/BaseContext'
import axios from 'axios'

type State = typeof state

type Actions = {
    loadPlans(context: Context): Promise<void>
}

type Mutations = {
    addPlans(state: State, plans: PlanResource[]): void
}

type Getters = {
    plans(state: State): Plan[]
    goldPlan(state: State): Plan
    silverPlan(state: State): Plan
}

type Context = BaseContext<State, Actions, Getters, Mutations>

const state: Record<string, Plan> = {}

const actions: Actions = {
    async loadPlans({ commit }: Context): Promise<void> {
        const username = appConfig.username
        const password = appConfig.password
        const client = axios.create(Object.assign({}, options))
        let plans = <PlanResource[]>(await client.get('plans', { auth: { username, password } })).data.data
        // TODO: Remove in future
        // The removal of "Package" from the plan name is intended to be a
        // temporary solution until the plan name is changed system-wide,
        // though this is not yet possible due a business limitation.
        plans = plans.map(p => {
            p.name = p.name.replace('Package', '').trim()
            return p
        })
        commit('addPlans', plans)
    },
}

const mutations: Mutations = {
    addPlans(state: State, plans: PlanResource[]): void {
        plans.forEach(p => {
            state[p.slug] = <Plan>{
                id    : p.id,
                name  : p.name,
                slug  : p.slug,
                desc  : p.desc,
                amount: p.amount,
            }
        })
    },
}

const getters: Getters = {
    plans(state: State): Plan[] {
        return Object.keys(state).map(slug => state[slug])
    },
    goldPlan(state: State): Plan {
        return state.gold ?? null
    },
    silverPlan(state: State): Plan {
        return state.silver ?? null
    },
}

export default { state, actions, mutations, getters }
