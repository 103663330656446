import { Style } from '@/enums/Style'
import { StyleQuizResource } from '@/models/api/resource/StyleQuizResource'
import { StyleQuizOption } from '@/models/StyleQuizOption'
import StyleQuizService from '@/services/StyleQuizService'
import { BaseActions } from '@/store/BaseActions'

export declare const styleQuizScores: Record<string, number>
export declare const styleQuizSelections: StyleQuizOption[]
export declare const styleQuizResults: StyleQuizResource[]

export declare function appendStyleQuizSelection(option: StyleQuizOption): void
export declare function removeStyleQuizSelection(option: StyleQuizOption): void
export declare function clearStyleQuizSelections(): void
export declare function loadStyleQuizResults(): Promise<void>
export declare function saveStyleQuizResults(): Promise<void>

type State = {
    styleQuizResults: typeof styleQuizResults
    styleQuizScores: typeof styleQuizScores
    styleQuizSelections: typeof styleQuizSelections
}

type Actions = {
    loadStyleQuizResults: typeof loadStyleQuizResults
    saveStyleQuizResults: typeof saveStyleQuizResults
    appendStyleQuizSelection: typeof appendStyleQuizSelection
    removeStyleQuizSelection: typeof removeStyleQuizSelection
    clearStyleQuizSelections: typeof clearStyleQuizSelections
}

type Mutations = {
    SET_STYLE_QUIZ_RESULTS(state: State, payload: { results: StyleQuizResource[] }): void
    APPEND_STYLE_QUIZ_SELECTION(state: State, payload: { option: StyleQuizOption }): void
    REMOVE_STYLE_QUIZ_SELECTION(state: State, payload: { option: StyleQuizOption }): void
    CLEAR_STYLE_QUIZ_SELECTIONS(state: State): void
}

type Getters = {
    styleQuizResults(state: State): typeof styleQuizResults
    styleQuizScores(state: State): typeof styleQuizScores
    styleQuizSelections(state: State): typeof styleQuizSelections
}

const state: State = {
    styleQuizResults: [],
    styleQuizScores: {},
    styleQuizSelections: [],
}

const actions: BaseActions<State, Actions, Getters, Mutations> = {
    async loadStyleQuizResults({ state, commit }) {
        if (state.styleQuizResults.length !== 0) {
            return
        }
        // const results = await StyleQuizService.getStyleResults()
        // commit('SET_STYLE_QUIZ_RESULTS', { results })
    },
    async saveStyleQuizResults({ state, commit, rootGetters }) {
        const allScores      = <number[]>Array
            .from(new Set(Object.keys(state.styleQuizScores).map(style => state.styleQuizScores[style])))
            .sort()
            .reverse()
        const dominantScore  = <number>allScores.shift()
        const alternateScore = <number>allScores.shift()
        let results          = Object.keys(state.styleQuizScores)
            .filter(style => state.styleQuizScores[style] === dominantScore)
            .map(style => ({ style_id: <Style>style, score: state.styleQuizScores[style] }))
        if (results.length < 2 && alternateScore) {
            results = results.concat(
                Object.keys(state.styleQuizScores)
                    .filter(style => state.styleQuizScores[style] === alternateScore)
                    .map(style => ({ style_id: <Style>style, score: state.styleQuizScores[style] }))[0],
            )
        }
        const resources = await StyleQuizService.createStyleQuiz({ results })
        commit('SET_STYLE_QUIZ_RESULTS', { results: resources })
        commit('CLEAR_STYLE_QUIZ_SELECTIONS')
    },
    appendStyleQuizSelection({ commit }, option) {
        commit('APPEND_STYLE_QUIZ_SELECTION', { option })
    },
    removeStyleQuizSelection({ commit }, option) {
        commit('REMOVE_STYLE_QUIZ_SELECTION', { option })
    },
    clearStyleQuizSelections({ commit }) {
        commit('CLEAR_STYLE_QUIZ_SELECTIONS')
    },
}

const mutations: Mutations = {
    APPEND_STYLE_QUIZ_SELECTION(state, { option }) {
        state.styleQuizSelections.push(option)
        state.styleQuizScores[option.style] = (state.styleQuizScores[option.style] ?? 0) + option.score
    },
    REMOVE_STYLE_QUIZ_SELECTION(state, { option }) {
        state.styleQuizSelections.splice(state.styleQuizSelections.indexOf(option), 1)
        state.styleQuizScores[option.style] = state.styleQuizScores[option.style] - option.score
    },
    CLEAR_STYLE_QUIZ_SELECTIONS(state) {
        state.styleQuizSelections.length = 0
        Object.keys(state.styleQuizScores).forEach(k => delete state.styleQuizScores[k])
    },
    SET_STYLE_QUIZ_RESULTS(state, { results }) {
        state.styleQuizResults.length = 0
        results.forEach(r => state.styleQuizResults.push(r))
    },
}

const getters: Getters = {
    styleQuizScores(state) {
        return state.styleQuizScores
    },
    styleQuizSelections(state) {
        return state.styleQuizSelections
    },
    styleQuizResults(state) {
        return state.styleQuizResults.slice(0, 2)
    },
}

export default { state, actions, getters, mutations }
