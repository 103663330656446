import { formatDistance } from 'date-fns'
import { DateTime, Interval } from 'luxon'

export function getElapsedTime(date: Date): string {
    return formatDistance(date, new Date(), { addSuffix: true })
}

/**
 * Extracts a DateTime instance for each day present in the given Interval instances.
 */
export function extractDatesFromIntervals(intervals: Interval[], timezone: string = 'UTC'): DateTime[] {
    const dates = new Set()
    for (const i of intervals) {
        dates.add(i.start.setZone(timezone).toFormat('yyyy-MM-dd'))
    }
    return Array.from(dates.values()).map((d: string) => DateTime.fromISO(d + 'T00:00:00Z'))
}

/**
 * Returns the number of seconds elapsed from the current date/time.
 *
 * @param {Date} date
 */
export function elapsedSeconds(date: Date): number {
    return (+(new Date(Date.now())) - +date) / 1000
}

/**
 * Returns a human-readable representation of the DateTime object.
 */
export function formatDateTime(dateTime: DateTime, dateFormat: string = 'DDDD', timeFormat: string = 'hh:mm a', separator: string = 'at'): string {
    const formattedDate = dateTime.toFormat(dateFormat)
    const formattedTime = dateTime.toFormat(timeFormat)
    return `${formattedDate} ${separator} ${formattedTime}`
}

/**
 * Returns a human-readable representation of the Interval start and end times.
 */
export function formatIntervalTime(interval: Interval, timezone: string = 'UTC'): string {
    const starTime = interval.start.setZone(this.state.selectedTimezone).toFormat('hh:mm')
    const endTime = interval.end.setZone(this.state.selectedTimezone).toFormat('hh:mm')
    const endPeriod = interval.end.setZone(this.state.selectedTimezone).toFormat('a')
    return `${starTime} - ${endTime} ${endPeriod}`
}

/**
 * Determines whether the DateTime is in the morning.
 */
export function isMorning(dateTime: DateTime, timezone: string = 'UTC'): boolean {
    dateTime = dateTime.setZone(timezone)
    return dateTime.hour >= 0 && dateTime.hour < 12
}

/**
 * Determines whether the DateTime is in the afternoon.
 */
export function isAfternoon(dateTime: DateTime, timezone: string = 'UTC'): boolean {
    dateTime = dateTime.setZone(timezone)
    return dateTime.hour >= 12 && dateTime.hour < 18
}

/**
 * Determines whether the DateTime is in the evening.
 */
export function isEvening(dateTime: DateTime, timezone: string = 'UTC"'): boolean {
    dateTime = dateTime.setZone(timezone)
    return dateTime.hour >= 18
}

/**
 * Determines whether both DateTime instances are on the same date, regardless
 * of the time.
 */
export function isSameDate(dateTimeA: DateTime, dateTimeB: DateTime, timezone: string = 'UTC'): boolean {
    return dateTimeA.setZone(timezone).toFormat('yyyy-MM-dd') === dateTimeB.setZone(timezone).toFormat('yyyy-MM-dd')
}
